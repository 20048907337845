import { HubConnection } from "@microsoft/signalr";
import { fetchStateType } from "../../models/types";
import {
  SET_CURRENT_GROUP_LOCKED,
  SET_CURRENT_TENANT_GROUP_ID,
  SET_SIGNALR_FETCH_STATE,
} from "./actionTypes";
import store, { AppDispatch } from "..";
import { signalRFunctions } from "../../config";

export const changeTenantIdGroup =
  (hubConnection: HubConnection, tenantIdentifier: string) =>
   async (dispatch: AppDispatch) => {
    let isLocked = store.getState().signalrReducer.groupIsLocked;

    if (isLocked) return;
    
    let fetchState = store.getState().signalrReducer.connectionIdFetchState;
    
    if (fetchState === "loading") return;

    let oldGroup = store.getState().signalrReducer.currentTenantGroup;

    if (oldGroup) await leaveGroup(hubConnection, oldGroup);
    await joinGroup(hubConnection, tenantIdentifier);

    return dispatch(setCurrentGroup(tenantIdentifier));
  };

export const forceChangeTenantIdGroup =
  (hubConnection: HubConnection, tenantIdentifier: string) =>
  async (dispatch: AppDispatch) => {

    
    let fetchState = store.getState().signalrReducer.connectionIdFetchState;
    
    if (fetchState === "loading") return;

    let oldGroup = store.getState().signalrReducer.currentTenantGroup;

    if (oldGroup) await leaveGroup(hubConnection, oldGroup);
    await joinGroup(hubConnection, tenantIdentifier);

    return dispatch(setCurrentGroup(tenantIdentifier));
  };

export const setConnectionIdFetchState = (fetchState: fetchStateType) => {
  return { type: SET_SIGNALR_FETCH_STATE, payload: fetchState };
};

export const setLockCurrentGroup = (isLocked: boolean) => {
  return { type: SET_CURRENT_GROUP_LOCKED, payload: isLocked };
};

const setCurrentGroup = (tenantIdentifier: string) => {
  return { type: SET_CURRENT_TENANT_GROUP_ID, payload: tenantIdentifier };
};

async function leaveGroup(hubConnection: HubConnection, oldGroup: string) {
  try {
    await hubConnection.invoke(
      signalRFunctions.tenant.LeaveTenantGroup,
      oldGroup
    );
    // console.log(`Left group ${oldGroup}`);

  } catch (err) {
    console.error("Error when leaving group", err);
  }
}
async function joinGroup(
  hubConnection: HubConnection,
  tenantIdentifier: string
) {
  try {
    await hubConnection.invoke(
      signalRFunctions.tenant.JoinTenantGroup,
      tenantIdentifier
    );
    // console.log(`Joined group ${tenantIdentifier}`);
    
  } catch (err) {
    console.error("Error when joining group", err);
  }
}
