import { useNavigate } from "react-router-dom"
import { pathToAdminDevice } from "../../utilities/paths";
import {useTenantNavigate} from '../../utilities/helpers';





export const useAdminDeviceNavigation = () => {
    const navigate = useTenantNavigate();


    const navigateToAdminDevice = (serialNumber:string)=>{
        let path = pathToAdminDevice(serialNumber)
        navigate(path, { state: { internal: true } })
    }


    return navigateToAdminDevice
}
