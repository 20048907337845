import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { DeviceInstallation } from "../../models";
import { RoomModel } from "../../models/locations.model";
import { useMemo, useState } from "react";
import { useAdminDevice } from "../../hooks/admin-device/useAdminDeviceHooks";
import { toName } from "../../models/detailedDevice.model";

type props = {
  deviceInstallation: DeviceInstallation | null;
  serialNumber: string;
  room: RoomModel | null;
  onConfirmed: () => void;
  confirmedOffline: boolean
};

const OverviewStep = ({
  serialNumber,
  deviceInstallation,
  room,
  onConfirmed,
  confirmedOffline
}: props) => {
  const { adminDevice: device, fetchState } = useAdminDevice(serialNumber);

  const labels = useMemo(
    () => [
      <Typography>You are at <b>{deviceInstallation?.tenant.name}</b></Typography>,
      <Typography>
        The Hand Sensor is installed in room <b>{room?.name}</b> on floor{" "}
        <b>{room?.floor?.name}</b>
      </Typography>,
      <Typography>
        {" "}
        The Hand Sensor is detecting <b>{toName(device?.fluid.type ?? "Alcohol")}</b>
      </Typography>,
    ],
    [device, room, deviceInstallation]
  );

  const [checked, setChecked] = useState<boolean[]>(
    new Array(labels.length).fill(false)
  );

  const confirmDisabled = useMemo(() => checked.some((ch) => !ch) || confirmedOffline, [checked, confirmedOffline]);

  const updateChecked = (ind: number, value: boolean) =>
    setChecked((currentChecked) => {
      currentChecked[ind] = value;
      return [...currentChecked];
    });

  const handelConfirm = onConfirmed;

  if (!deviceInstallation)
    return <Alert severity="error">Error: deviceInstallation is null</Alert>;
  return (
    <>
      <Grid container flexDirection={"column"} alignContent={"space-between"}>
        {fetchState === "loading" && (
          <Grid
            item
            container
            justifyContent={"center"}
            alignContent={"center"}
            minHeight={300}
          >
            <CircularProgress />
          </Grid>
        )}
        {fetchState === "fetched" && (
          <>
            <Grid item>Ensure that all of these are correct:</Grid>
            <Grid item padding={1}>
              <FormGroup>
                {labels.map((label, ind) => {
                  const handleCheckboxChanged = (
                    _: React.SyntheticEvent<Element, Event>,
                    checked: boolean
                  ) => updateChecked(ind, checked);

                  return (
                    <FormControlLabel
                      key={ind}
                      value={checked[ind]}
                      onChange={handleCheckboxChanged}
                      control={<Checkbox />}
                      sx={{marginBottom: 2}}
                      label={label}
                    />
                  );
                })}
              </FormGroup>
            </Grid>
            <Grid item alignSelf={"center"} marginTop={2}>
              <Button
                variant="outlined"
                disabled={confirmDisabled}
                onClick={handelConfirm}
              >
                Confirm installation
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default OverviewStep;