import { ErrorOutline } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import React from "react";
import { Tenant } from "../../models";
import Linkage from "../link/Linkage";
import Title from "../title/Title";

type props = { deviceId: string | undefined; tenant?: Tenant | null };

const DeviceNotFound = ({ deviceId, tenant = null }: props) => {
	return (
		<>
			<Title>{tenant ? `Device not at this tenant` : `Device not found`}</Title>
			<Grid
				container
				justifyContent={"center"}
				textAlign="center"
				flexDirection="column"
				marginTop={5}
			>
				{tenant ? (
					<>
						<Grid item marginTop={5}>
							<Linkage to={`/device/${deviceId}`}>Go to device</Linkage>
						</Grid>
					</>
				) : (
					<>
						<Typography>
							<ErrorOutline />
						</Typography>
						<Typography textAlign={"center"}>
							Unable to find device with id <br />
							{deviceId}
						</Typography>
						<Grid item marginTop={5}>
							<Linkage to={`/devices`}>Go to list of all devices</Linkage>
						</Grid>
					</>
				)}
			</Grid>
		</>
	);
};

export default DeviceNotFound;
