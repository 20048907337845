import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useMemo } from "react";
import ClickableTooltip from "../clickable-tooltip/ClickableTooltip";

type props = {
  fontsize?: "small" | "inherit" | "large" | "medium";

  isGray?: boolean
};
const SignalRHeart = ({ fontsize = "medium", isGray }: props) => {
  const fetchState = useSelector(
    (state: RootState) => state.signalrReducer.connectionIdFetchState
  );

  const tooltipText = useMemo(() => {
    switch (fetchState) {
      case "error":
        return "Unable to receive live events";
      case "fetched":
        return "Receiving live events";
      case "loading":
      case "default":
      default:
        return "Connecting to real time server ...";
    }
  }, [fetchState]);

  const icon = useMemo(() => {
    if (fetchState === "default" || fetchState === "loading")
      return <FavoriteIcon fontSize={fontsize} htmlColor={"#ccd6dd"} />;

    if (fetchState === "error")
      return <HeartBrokenIcon fontSize={fontsize} htmlColor="#e9737b" />;

    if(isGray)
      return <FavoriteIcon fontSize={fontsize} htmlColor={"#ccd6dd"} />;

    return <FavoriteIcon fontSize={fontsize} htmlColor={"#18be94"} />;
  }, [fetchState,isGray, fontsize]);

  return <ClickableTooltip title={tooltipText}>{icon}</ClickableTooltip>;
};


export default SignalRHeart;
