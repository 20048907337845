import { useState } from "react";
import { Tenant } from "../../models";
import OnPremTenantSelector from "../on-prem-tenant-selector/OnPremTenantSelector";
import { Alert, Button, Grid, Typography } from "@mui/material";
import TenantDisplay from "../tenant-display/TenantDisplay";
import { useServiceTenant } from "../../hooks/tenant";
import { useDispatch } from "react-redux";
import { newTenant } from "../../store/actions/tenantActions";
import { DetailedDevice } from "../../models/detailedDevice.model";

type props = {
	device: DetailedDevice;
	onTenantChosen: (tenant: Tenant) => void;
};

const TenantSelectionStep = ({ device, onTenantChosen }: props) => {
	const { tenant: serviceTenant } = useServiceTenant();
	const [retrieveFromPosition, setRetrieveFromPosition] = useState(true);
	const [manualSelection, setManualSelection] = useState(false);
	const dispatch = useDispatch();

	const [chosenTenant, setChosenTenant] = useState<Tenant | null>();

	const handleTenantChosen = (tenant: Tenant) => {
		setChosenTenant(tenant);
		setRetrieveFromPosition(false);
		setManualSelection(false);
		onTenantChosen(tenant);
		dispatch(newTenant(tenant));
	};

	const handleTenantFetchError = () => {
		setRetrieveFromPosition(false);

		setManualSelection(true);
	};

	return (
		<Grid
			container
			flexDirection={"column"}
			alignContent={"center"}
			minHeight={"inherit"}
			rowSpacing={4}
		>
			<Grid
				item
				flexDirection={"column"}
				alignContent={"center"}
				justifySelf={"flex-end"}
			>
				{chosenTenant && (
					<Alert severity="info">
						<Typography>
							Currently installing at <b>{chosenTenant.name}</b>.
						</Typography>
					</Alert>
				)}
			</Grid>

			<Grid
				item
				container
				flexDirection={"column"}
				alignContent={"center"}
				rowSpacing={2}
			>
				{!retrieveFromPosition && !manualSelection && chosenTenant && (
					<Grid item>
						<Button variant="outlined" onClick={() => setManualSelection(true)}>
							Choose different tenant
						</Button>
					</Grid>
				)}

				{manualSelection && (
					<TenantDisplay
						onTenantChosen={handleTenantChosen}
						loadingComponent="skeleton"
						disabledTenantIds={[
							serviceTenant?.identifier ?? "",
							chosenTenant?.identifier ?? "",
							device.tenant?.identifier ?? "",
						]}
					/>
				)}
			</Grid>
			<Grid item>
				{retrieveFromPosition && (
					<OnPremTenantSelector
						onTenantChosen={handleTenantChosen}
						onTenantFetchError={handleTenantFetchError}
					/>
				)}
			</Grid>
		</Grid>
	);
};

export default TenantSelectionStep;
