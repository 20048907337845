import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { newTenant, setTenantFetchState } from "../store/actions/tenantActions";
import { RootState } from "../store/reducers";
import { setTenantHeaderInAxios } from "../utilities/axios";
import { getTenantByIdentifier } from "../utilities/axios/admin-api-calls/adminCalls";
import { getTenantFromStorage } from "../utilities/storage/tenantStorage";
import { setEventFetchState } from "../store/actions/eventActions";
import { fetchStateType } from "../models/types";
import useBroadcaster from "./signalR-hooks/signalRHook";
import { changeTenantIdGroup } from "../store/actions/signalrActions";

export const useFindAndPersistTenant = () => {
	const { tenantIdentifier: tenantIdParams } = useParams();
	const [fetchState, setFetchState] = useState<fetchStateType>("default");

	const dispatch = useDispatch();

	const storeTenant = useSelector(
		(state: RootState) => state.tenantReducer.tenant,
	);

	const signalRConnectionFetchState = useSelector(
		(state: RootState) => state.signalrReducer.connectionIdFetchState,
	);

	const connection = useBroadcaster();

	const findTenant = useCallback(
		async (identifier: string) => {
			setFetchState("loading");
			//temporarily set axios header to the unknown tenant from path param
			setTenantHeaderInAxios({ identifier, name: "unknown", realm: "unknown" });

			let tenant = await getTenantByIdentifier(identifier);
			if (tenant) {
				dispatch(newTenant(tenant, connection));
				dispatch(setEventFetchState("default"));
				setFetchState("fetched");
			} else {
				dispatch(setTenantFetchState("error"));
				setFetchState("error");
			}
		},
		[dispatch, connection],
	);

	useEffect(() => {
		if (
			storeTenant &&
			storeTenant?.identifier !== tenantIdParams &&
			tenantIdParams
		) {
			findTenant(tenantIdParams);
		} else if (!storeTenant && tenantIdParams) {
			findTenant(tenantIdParams);
		} else if (!storeTenant && !tenantIdParams) {
			let storageTenant = getTenantFromStorage();
			if (storageTenant) dispatch(newTenant(storageTenant, connection));
		}
	}, [tenantIdParams, connection, storeTenant, findTenant, dispatch]);

	useEffect(() => {
		if (
			connection &&
			storeTenant &&
			signalRConnectionFetchState === "fetched"
		) {
			dispatch(changeTenantIdGroup(connection, storeTenant.identifier));
		}
	}, [connection, storeTenant, signalRConnectionFetchState, dispatch]);

	return { fetchState };
};

export const useTenant = () => {
	const tenant = useSelector((state: RootState) => state.tenantReducer.tenant);

	return tenant;
};

export const useServiceTenant = () => {
	const serviceTenant = useSelector(
		(state: RootState) => state.maintenanceReducer.serviceTenant,
	);

	return serviceTenant;
};

export const useIsServiceTenant = () => {
	const serviceTenant = useServiceTenant();
	const currentTenant = useTenant();

	const isServiceTenant = useMemo(() => {
		return serviceTenant.tenant?.identifier === currentTenant?.identifier;
	}, [serviceTenant, currentTenant]);

	return {
		fetchState: serviceTenant.fetchState,
		isServiceTenant: isServiceTenant,
	};
};

export const useTenantLinks = () => {
	const tenant = useTenant();
	const currentDashboardLink = useMemo(() => `/dashboard`, [tenant]);

	return {
		currentDashboardLink,
	};
};
