
class StorageService {
	static setItem<T>(key: string, value: T): void {
		try {
			// Special case for strings
			if (typeof value === 'string') {
				localStorage.setItem(key, value);
			} else {
				localStorage.setItem(key, JSON.stringify(value));
			}
		} catch (error) {
			console.error('Error saving to localStorage:', error);
		}
	}

	static getItem<T>(key: string, defaultValue: T): T {
		try {
			const item = localStorage.getItem(key);
			if (item === null) return defaultValue;

			// Special case for strings
			if (typeof defaultValue === 'string') {
				return item as T;
			}
			return JSON.parse(item);
		} catch (error) {
			console.error('Error reading from localStorage:', error);
			return defaultValue;
		}
	}

	// Remove item
	static removeItem(key: string): void {
		try {
			localStorage.removeItem(key);
		} catch (error) {
			console.error('Error removing from localStorage:', error);
		}
	}

	// Clear all
	static clear(): void {
		try {
			localStorage.clear();
		} catch (error) {
			console.error('Error clearing localStorage:', error);
		}
	}
}

const StorageKeys = {
	PartnerId: "partner-id"
}

export {StorageService, StorageKeys}
