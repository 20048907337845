import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { App } from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import "./index.scss";
import store from "./store";
import { Box, ThemeProvider } from "@mui/material";
import muiTheme from "./styles/muiTheme";
import AuthContextProvider from "./provider/AuthContextProvider";
import { NoPartnerId } from "./components/no-partner-id/NoPartnerId";
import { StorageKeys, StorageService } from "./utilities/storage/local-storage";
import { useIsAuthenticated } from "./hooks/authentication-hooks/authenticationHooks";
import { extractRealmFromUrl } from "./utilities/helpers";

const container = document.getElementById("root");

const handlePartnerId = (partnerId: string) => {
	const url = new URL(window.location.href);
	const pathWithoutLeadingSlash = url.pathname.replace(/^\//, "");
	const newPathname = `/${partnerId}/${pathWithoutLeadingSlash}`;

	if (!url.pathname.includes(partnerId))
		window.location.href = url.origin + newPathname + url.search;
};

const Temp = () => {
	const [ready, setReady] = useState(false);
	const [realm, setRealm] = useState("");
	const { isAuthenticated } = useIsAuthenticated();

	useEffect(() => {
		const i = setInterval(() => {
			const realm = StorageService.getItem(StorageKeys.PartnerId, "NONE");

			if (realm !== "NONE") {
				clearInterval(i);
				setRealm(realm);
				handlePartnerId(realm);
			}
		}, 100);

		return () => {
			clearInterval(i);
		};
	}, [isAuthenticated, ready]);

	useEffect(() => {
		const x = extractRealmFromUrl();

		const invalidRedirects = ["devices", "", null];

		if (!invalidRedirects.includes(x)) setReady(true);
	}, []);

	if (!ready) return <NoPartnerId />;

	return (
		<AuthContextProvider>
			<BrowserRouter basename={realm}>
				<Routes>
					<Route
						path="*"
						element={
							<>
								<Provider store={store}>
									<App />
								</Provider>
							</>
						}
					/>
				</Routes>
			</BrowserRouter>
		</AuthContextProvider>
	);
};

const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
	<React.StrictMode>
		<ThemeProvider theme={muiTheme}>
			<Temp />
		</ThemeProvider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
