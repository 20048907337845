import { DeviceInstallation } from "../../models"
import { RoomModel } from "../../models/locations.model"


const PREV_INSTALL_STORAGE_KEY = 'PREV_INSTALL_STORAGE_KEY' 
const PREV_INSTALL_ROOM_STORAGE_KEY = 'PREV_INSTALL_ROOM_STORAGE_KEY'


export const setPrevInstallInStorage = (install:DeviceInstallation, room: RoomModel) =>{
    sessionStorage.setItem(PREV_INSTALL_STORAGE_KEY, JSON.stringify(install))
    sessionStorage.setItem(PREV_INSTALL_ROOM_STORAGE_KEY, JSON.stringify(room))
    
}

export const getPrevInstallFromStorage: () => DeviceInstallation | null = () => {
    let install = sessionStorage.getItem(PREV_INSTALL_STORAGE_KEY)
    if(!install) return null
    return JSON.parse(install) as DeviceInstallation
}


export const getPrevInstallRoomFromStorage: () => RoomModel | null = () => {
    let install = sessionStorage.getItem(PREV_INSTALL_ROOM_STORAGE_KEY)
    if(!install) return null
    return JSON.parse(install) as RoomModel
}