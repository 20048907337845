import {
  Box,
  Typography,
  Button,
  MobileStepper,
  Paper,
  useTheme,
  Skeleton,
  Grid,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CompleteInstallDto,
  DeviceInstallation,
  DeviceInstallationStatus,
  isReadyOrInProgress,
  Tenant,
} from "../../models";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { RoomModel } from "../../models/locations.model";
import InstallLocationSelector from "./InstallLocationSelector";
import { useAdminDevice } from "../../hooks/admin-device/useAdminDeviceHooks";
import OverviewStep from "./OverviewStep";
import FluidStep from "./FluidStep";
import { useIsSmallScreen } from "../../hooks/media-hooks/mediaHooks";
import { useParams } from "react-router-dom";
import TenantSelectionStep from "./TenantSelectionStep";
import {
  useDeviceInstallationsForDevice,
  useUpdateDeviceInstaller,
  useUpdateDeviceStatus,
} from "../../hooks/device-installations/deviceInstallationHooks";
import { fluidKind } from "../../models/detailedDevice.model";
import WallMountInstructionStep from "./WallMountInstructionStep";
import ImageUploadStep from "./ImageUploadStep";
import { useListenForEvents } from "../../hooks/deviceEvent-hooks/mostRecentEventsHook";
import SignalRHeart from "../signalr-heart/SignalRHeart";
import { useBroadcasterIdFetchState } from "../../hooks/signalR-hooks/signalRHook";
import { setPrevInstallInStorage } from "../../utilities/storage/prevDeviceInstallStorage";

const steps = [
  "Select Tenant",
  "Select Room",
  "Liquid",
  "Turn on hand sensor",
  "Check hand sensor",
  "Mount on wall",
  "Take a picture of the device",
  "Overview",
];

type props = {
  onConfirm: (
    completeInstallDto: CompleteInstallDto,
    deviceInstallation: DeviceInstallation
  ) => void;
};

const InstallationStepper = ({ onConfirm }: props) => {
  const { serialNumber } = useParams();
  const smallScreen = useIsSmallScreen();
  const maxSteps = steps.length;
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [roomChosen, setRoom] = useState<RoomModel | null>(null);
  const [roomConfirmed, setRoomConfirmed] = useState(false);
  const [tenantSelected, setTenantSelected] = useState<Tenant | null>(null);
  const [imageSkip, setImageSkip] = useState(false);
  const [fluidKind, setFluidKind] = useState<fluidKind | null>(null);
  const { adminDevice: device, fetchState: deviceFetchState } =
    useAdminDevice(serialNumber);
  const { count, startCount, resetCount } = useListenForEvents(device);

  const signalRFetchState = useBroadcasterIdFetchState();

  const confirmedOffline = useMemo(() => {
    if (count > 0) return false;

    if (signalRFetchState === "error") return false;

    return true;
  }, [signalRFetchState, count]);

  const {
    mostRecent: deviceInstallation,
    switchTenantInstall,
    handleImageAdded,
    resetImages,
    images,
    anyImages,
  } = useDeviceInstallationsForDevice(serialNumber ?? "unknown", [
    DeviceInstallationStatus.READY,
    DeviceInstallationStatus.IN_PROGRESS,
  ]);

  const handleTenantDecided = useCallback(
    async (tenant: Tenant) => {
      if (!serialNumber) return;

      setTenantSelected(tenant);
      resetImages();
      setRoomConfirmed(false);
      setRoom(null)

      await switchTenantInstall(tenant);
    },
    [serialNumber, resetImages, switchTenantInstall]
  );

  const handleImageSkipped = () => {
    setImageSkip(true);
  };

  const { update: updateDeviceInstallationStatus } =
    useUpdateDeviceStatus(deviceInstallation);
  const { update: updateDeviceInstaller } =
    useUpdateDeviceInstaller(deviceInstallation);

  useEffect(() => {
    if (
      deviceInstallation?.installationStatus === DeviceInstallationStatus.READY
    ) {
      updateDeviceInstallationStatus(DeviceInstallationStatus.IN_PROGRESS);
    }
  }, [deviceInstallation, updateDeviceInstallationStatus]);

  useEffect(() => {
    if (deviceInstallation) {
      if (isReadyOrInProgress(deviceInstallation.installationStatus)) {
        updateDeviceInstaller();
      }
    }
  }, [deviceInstallation, updateDeviceInstaller]);

  const handleConfirm = useCallback(() => {
    if (!deviceInstallation) return;

    let dto: CompleteInstallDto = {
      roomId: roomChosen?.id ?? "unknown",
    };

    onConfirm(dto, deviceInstallation);

    if (roomChosen) setPrevInstallInStorage(deviceInstallation, roomChosen);
  }, [onConfirm, roomChosen, deviceInstallation]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (activeStep === 1) {
      startCount();
    }
  }, [activeStep, startCount, resetCount]);

  const currentStepComponent = useMemo(() => {
    if (!device || deviceFetchState === "loading") return <></>;
    switch (activeStep) {
      case 0:
        return (
          <TenantSelectionStep
            onTenantChosen={handleTenantDecided}
            device={device}
          />
        );
      case 1:
        return (
          <InstallLocationSelector
            room={roomChosen}
            handleRoomChosen={(room) => {
              setRoomConfirmed(false);
              setRoom(room);
            }}
            onConfirm={(_) => setRoomConfirmed(true)}
          />
        );
      case 2:
        return (
          <FluidStep
            device={device}
            tenant={tenantSelected!}
            fluidKind={fluidKind}
            onFluidSelected={(fk) => setFluidKind(fk)}
          />
        );
      case 3:
        return (
          <img
            src="\turn_on_handsensor_pic.png"
            alt="Turn on Handsendsor"
            style={{ width: "100%", maxWidth: "400px", height: "auto" }}
          />
        );
      case 4:
        return (
          <img
            src="\turn_on_handsensor_pic_2.png"
            alt="Turn on Handsendsor"
            style={{ width: "100%", maxWidth: "400px", height: "auto" }}
          />
        );
      case 5:
        return <WallMountInstructionStep />;
      case 6:
        return (
          <ImageUploadStep
            images={images}
            deviceInstallation={deviceInstallation!}
            onImageAdded={handleImageAdded}
            onImageError={() => {}}
            onImageStepSkipped={handleImageSkipped}
          />
        );
      case 7:
        return (
          <OverviewStep
            deviceInstallation={deviceInstallation}
            serialNumber={device.serialNumber}
            room={roomChosen}
            confirmedOffline={confirmedOffline}
            onConfirmed={handleConfirm}
          />
        );

      default:
        return <>Step {activeStep} not implemented</>;
    }
  }, [
    device,
    handleConfirm,
    handleTenantDecided,
    handleImageAdded,
    images,
    tenantSelected,
    activeStep,
    deviceInstallation,
    roomChosen,
    deviceFetchState,
    fluidKind,
    confirmedOffline,
  ]);

  const isNextDisabled = useMemo(() => {
    switch (activeStep) {
      case 0:
        return (
          !tenantSelected ||
          !deviceInstallation ||
          deviceInstallation.tenant.identifier !== tenantSelected.identifier
        );
      case 1:
        return !roomConfirmed;

      case 2:
        return !fluidKind;

      case 3:
        return false;

      case 4:
        return false;

      case 5:
        return false;

      case 6:
        return !anyImages && !imageSkip;

      default:
        return activeStep === maxSteps - 1;
    }
  }, [
    activeStep,
    deviceInstallation,
    roomConfirmed,
    fluidKind,
    tenantSelected,
    maxSteps,
    anyImages,
    imageSkip,
  ]);

  return (
    <>
      <Box sx={{ maxWidth: "100vw", width: 400, height: 500, flexGrow: 1 }}>
        {deviceFetchState !== "fetched" ? (
          <Skeleton width={400} height={500} variant="rectangular" />
        ) : (
          <Paper
            square
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              height: 75,
              pl: 2,
              bgcolor: "background.default",
            }}
          >
            <Grid container flexDirection={"column"} rowSpacing={1}>
              <Grid item>
                <Typography>
                  <b>{steps[activeStep]}</b>
                </Typography>
              </Grid>
              <Grid item justifyContent={"center"} flexDirection={"column"}>
                <SignalRHeart isGray={count === 0} fontsize="small" />{" "}
                {signalRFetchState === "fetched" && (
                  <>{count > 0 ? `Device is online` : `Device is offline`} </>
                )}
                {signalRFetchState === "error" && <>Unable to get events </>}
              </Grid>
            </Grid>
          </Paper>
        )}
        {device && (
          <Box sx={{ maxWidth: 400, minHeight: 400 }}>
            {currentStepComponent}
          </Box>
        )}
        <MobileStepper
          variant="dots"
          steps={maxSteps}
          position={smallScreen ? "bottom" : "static"}
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={isNextDisabled}>
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </Box>
    </>
  );
};

export default InstallationStepper;
