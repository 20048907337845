import { Grid, ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";
import {
  allFluidKinds,
  DetailedDevice,
  fluidKind,
  toName,
} from "../../models/detailedDevice.model";
import { updateFluidKind } from "../../utilities/axios/admin-api-calls/tenant-calls";
import { Tenant } from "../../models";
import { useState, useCallback } from "react";
import { saveStateType } from "../../models/types";
import { setFluid } from "../../store/actions/detailedDeviceActions";

type props = {
  device: DetailedDevice;
  tenant: Tenant;
  onFluidSelected: (value: fluidKind) => void;
  fluidKind: fluidKind | null;
};
const FluidStep = ({ device, tenant, fluidKind, onFluidSelected }: props) => {
  const theme = useTheme();

  const [saveState, setSaveState] = useState<saveStateType>("default");

  const callUpdateFluidKind = useCallback(
    async (newFluidKind: fluidKind) => {
      if (saveState === "loading" || !tenant) return;

      setSaveState("loading");

      var newFluid = await updateFluidKind(
        device.serialNumber,
        tenant?.identifier,
        {
          type: newFluidKind,
        }
      );

      if (!newFluid) {
        setSaveState("error");
        return;
      }

      setFluid(newFluid);

      setSaveState("success");
    },
    [saveState, device, tenant]
  );

  const handleChange = (_: React.MouseEvent<HTMLElement>, value: fluidKind) => {
    callUpdateFluidKind(value);
    onFluidSelected(value);
  };

  return (
    <Grid container>
      <Grid
        container
        flexDirection={"column"}
        alignItems="center"
        rowSpacing={3}
      >
        <Grid item>
          Select the type of fluid {device.serialNumber} is observing.
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            value={fluidKind}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            {allFluidKinds.map((fk) => {
              return (
                <ToggleButton
                  key={fk}
                  value={fk}
                  disabled={fk === fluidKind}
                  sx={{ fontWeight: theme.typography.fontWeightRegular }}
                >
                  {toName(fk)}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FluidStep;
