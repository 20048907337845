
import { useCallback}  from "react";
import { getFloors } from "../../utilities/axios/admin-api-calls/tenant-calls/locationCalls";

import {
  PaginationQuery,

} from "../../utilities/api";
import ItemDisplay from "../display/ItemDisplay";
import { BaseModel } from "../../models";
import { FloorModel } from "../../models/locations.model";
import { Item } from "../../hooks/item-pagination-hooks/ItemPaginationHook";
import { Filter } from "../../models/filter.models";


type props = {
  onFloorChosen: (model: FloorModel) => void 
  disabledFloors? : string[]
  maxItemsPerPage?: number
  disableAutoSelectOnOneElement?: boolean
}

const FloorsDisplay = ({onFloorChosen, disabledFloors = [], maxItemsPerPage, disableAutoSelectOnOneElement}: props) => {

  const getFloorRoomsDisplaysPaginationQuery = useCallback(
    (paginationQuery: PaginationQuery, filter: Filter) =>
    getFloors(
        paginationQuery,
        filter.searchTerm
      ),
    []
  );

  const navigateToFloorRoom = (floor: BaseModel) => {
    onFloorChosen(floor as FloorModel)
  };

  const isDisabled = (item: Item) => {
    return disabledFloors.some(f => f === item.id)
  }

  return (
    <ItemDisplay 
    itemName="floors" 
    getItems={getFloorRoomsDisplaysPaginationQuery} 
    labelProperty={"name"} 
    handleItemChosen={navigateToFloorRoom}
    isDisabled={isDisabled}
    maxItemsPerPage={maxItemsPerPage}
    disableAutoSelectOnOneElement={disableAutoSelectOnOneElement}
     />
  );
};

export default FloorsDisplay;

