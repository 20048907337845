import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const STEPS = [
  "Degrease the wall.",
  "Remove liner from tape on the back of the Hand Sensor.",
  "Mount the Hand Sensor on wall.",
  "Mount drip tray depending on delivery.",
];
const WallMountInstructionStep = () => {
  return (
    <Grid container>

      <List>
        {STEPS.map((instruction, index) => {
          return (
            <ListItem key={index} >
              <ListItemText primaryTypographyProps={{variant: "body1"}}>
                <b>{index + 1}</b>: {instruction}
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );
};

export default WallMountInstructionStep;
