import {
  Box,
  Grid,
  ListItemButton,
  SxProps,
  Table,
  TableBody,
  TableHead,
  Theme,
} from "@mui/material";
import { Item } from "../../hooks/item-pagination-hooks/ItemPaginationHook";
import { BaseModel } from "../../models";
import {
  useIsMediumScreen,
  useIsMobile,
  useIsSmallScreen,
} from "../../hooks/media-hooks/mediaHooks";
import { useState, useRef, useEffect } from "react";

export interface ItemComponentProps {
  model: BaseModel;
  smallScreen?: boolean;
  mediumScreen?: boolean;
  mobile?: boolean;
  onModelChosen: (model: BaseModel) => void;
  disableDivideLastItem?: boolean;
}
type props = {
  items: Item[];
  onItemSelected: (item: Item) => void;
  onItemClicked: (item: Item) => void;
  selected: Item | null;
  disabledItemId?: string | null;
  itemComponent?: ((props: ItemComponentProps) => JSX.Element) | null;
  itemComponentProps?: ItemComponentProps;
  headerComponent?: (() => JSX.Element) | null;
  isTableRow?: boolean;
  isDisabled?: (item: Item) => boolean;
  disableDivideLastItem?: boolean;
  sx?: SxProps<Theme> | undefined;
};
export const itemSelectionFocus = {
  backgroundColor: "primary.main",
  color: "white",
  cursor: "pointer",
  fontWeight: 800,
};

const ItemList = ({
  items,
  onItemSelected,
  selected,
  onItemClicked,
  disabledItemId = null,
  itemComponent = null,
  itemComponentProps,
  headerComponent = null,
  isTableRow = false,
  disableDivideLastItem = false,
  isDisabled = (item: Item) => false,
}: props) => {
  const smallscreen = useIsSmallScreen();
  const mobile = useIsMobile();
  const mediumScreen = useIsMediumScreen();

  const [parentcomponentHeight, setParentComponentHeight] = useState(0);
  const myTableRef = useRef<HTMLTableElement>(null);
  useEffect(() => {
    const updateParentComponentHeight = () => {
      if (myTableRef.current) {
        const height = myTableRef.current.clientHeight;
        setParentComponentHeight(height);
      }
    };

    updateParentComponentHeight();
  }, []);

  const parentcomponentStyle = {
    minHeight: `${parentcomponentHeight}px`,
  };

  return (
    <Grid item sx={parentcomponentStyle}>
      <Table ref={myTableRef}>
        {!!headerComponent && (
          <TableHead>{!!headerComponent && { ...headerComponent() }}</TableHead>
        )}
        <TableBody>
          {items.map((item, index) => {
            const ItemRenderer = itemComponent || DefaultItemComponent;

            if (isTableRow && itemComponent)
              return (
                <ItemRenderer
                  key={item.id}
                  model={item}
                  smallScreen={smallscreen}
                  onModelChosen={onItemClicked}
                  mobile={mobile}
                  mediumScreen={mediumScreen}
                  {...itemComponentProps}
                />
              );

            let showDivider =
              !disableDivideLastItem || index !== items.length - 1;

            return (
              <Box key={`item/${item.id}`} component="tr">
                <Box component="td">
                  <ListItemButton
                    onFocus={() => onItemSelected(item)}
                    component="a"
                    onClick={() => onItemClicked(item)}
                    key={`item/${item.id}`}
                    disabled={disabledItemId === item.id || isDisabled(item)}
                    divider={showDivider}
                    sx={{
                      maxWidth: "100%",
                      textAlign: "center",
                      "&:focus": itemSelectionFocus,
                      "&:hover": itemSelectionFocus,
                      ...(selected?.id === item.id && itemSelectionFocus),
                    }}
                  >
                    <ItemRenderer
                      model={item}
                      smallScreen={smallscreen}
                      onModelChosen={onItemClicked}
                      {...itemComponentProps}
                    />
                  </ListItemButton>
                </Box>
              </Box>
            );
          })}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default ItemList;

const DefaultItemComponent: React.FC<ItemComponentProps> = ({ model }) => {
  let item = model as Item;

  if (item.label) return <>{item.label}</>;

  return <>{model.id} </>;
};
