import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFloorPlan } from "../../hooks/floor-plan-hooks/floorPlanHook";
import { Button } from "../buttons/Button";
import {useTenantNavigate} from '../../utilities/helpers';

const FloorPlanButton = () => {
  const navigate = useTenantNavigate();
  const floorplan = useFloorPlan();

  const handleFloorPlanClicked = () => {
    navigate("floor-plans");
  };

  return (
    <>
      {floorplan.fetchState ==="loading" || floorplan.fetchState === "default" ? (
        <Skeleton variant="rectangular" height={35} width={175} />
      ) : (
        <Button onClick={handleFloorPlanClicked}>
          {floorplan.floorPlan?.map ? `Edit floorplan` : `Add floorplan`}
        </Button>
      )}
    </>
  );
};

export default FloorPlanButton;
