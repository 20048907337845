import {  BaseModel, Firmware, Tenant } from ".";
import { FloorModel, PremiseModel, SectionModel } from "./locations.model";

export interface SensorErrorModel {
  sensor: string;
  status: string;
  statusReference: string;
}

export interface RemainderModel {
  percentLeft: number;
  remainingTimeInHours: number;
  lastReplenishedAt?: string;
  type: fluidKind
}


export type fluidKind = "Alcohol" | "Soap"

export function toName(fk: fluidKind) {
  switch (fk) {
    case "Alcohol":
      return "Disinfectant";
    case "Soap":
      return "Soap";
    default:
      return "unknown";
  }
}


export const allFluidKinds: fluidKind[] = ["Alcohol", "Soap"]

export interface UsageModel {
  total: number;
  last24Hours: number;
}

export interface DeviceRoomLocation {
  id: string;
  name: string;
  roomType: string;
}

export interface LocationModel {
  room: DeviceRoomLocation;

  section: SectionModel;

  premise: PremiseModel;
  floor: FloorModel;

  tenant: Tenant

  movedAt: Date;
}
export interface Battery {
  lastChangedAt?: Date;
  value?: number;
  expectedToRunOutAt?: Date;
}
export interface StatusModel {
  isOn: boolean;
  lastConnectedAt: string;
  runsOnBattery: boolean;
  battery?: Battery;
}

export interface DeviceModel extends BaseModel {
  name: string
  description: string
  serialNumberPrefix:string
  created: Date
  updated: Date
}

export interface DetailedDevice {
  location: LocationModel;
  serialNumber: string;
  status: StatusModel;
  fluid: RemainderModel;
  firmware: Firmware;
  isUpdatingFirmware: boolean;
  certificateExpirationDate: Date;
  tenant: Tenant | null;
  updated: Date;
  model: DeviceModel
}
