import { CircularProgress, Grid } from "@mui/material";
import { fetchStateType } from "../../models/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Tenant } from "../../models";
import { useServiceTenant } from "../../hooks/tenant";
import { useUserPosition } from "../../hooks/geoLocation-hooks/geoLocationHooks";
import { getClosestTenant } from "../../utilities/axios/admin-api-calls/adminCalls";

type props = {
  onTenantChosen: (tenant: Tenant) => void;
  width?: string | number;
  onTenantFetchError: () => void;
};

const OnPremTenantSelector = ({
  onTenantChosen,
  onTenantFetchError,
  width = "inherit",
}: props) => {
  const { tenant: serviceTenant } = useServiceTenant();

  const {
    position,
    canQuery,
    queryPosition,
    fetchState: positionFetchState,
  } = useUserPosition();

  const [proximityTenantFetchState, setProximityTenantFetchState] =
    useState<fetchStateType>("default");

  const loadingText = useMemo(() => {
    if (positionFetchState !== "loading") {
      return "Finding tenant closest to your proximity...";
    }

    return "Loading tenants";
  }, [positionFetchState]);

  const findTenantByProximity = useCallback(async () => {
    if (!position) return;
    setProximityTenantFetchState("loading");

    var result = await getClosestTenant({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      orderByProximity: true,
    });

    if (!result) {
      setProximityTenantFetchState("error");
      return;
    }
    setProximityTenantFetchState("fetched");
    let closestTenant = result;

    if (serviceTenant?.identifier === closestTenant.identifier) return;

    onTenantChosen(closestTenant);
  }, [position, onTenantChosen, serviceTenant]);

  const getPosition = useCallback(() => {
    if (!canQuery || position) return;
    queryPosition();
  }, [position, canQuery, queryPosition]);

  useEffect(() => {
    if (positionFetchState === "loading") {
      setProximityTenantFetchState("loading");
    }
    if (positionFetchState === "error") {
      setProximityTenantFetchState("error");
    }
  }, [positionFetchState]);

  useEffect(() => {
    if (canQuery) {
      getPosition();
      findTenantByProximity();
    } else {
      setProximityTenantFetchState("error");
    }
  }, [getPosition, findTenantByProximity, position, canQuery]);

  useEffect(() => {
    if (proximityTenantFetchState === "error") onTenantFetchError();
  }, [proximityTenantFetchState, onTenantFetchError]);

  return (
    <Grid item>
      {proximityTenantFetchState === "loading" && (
        <Grid
          container
          flexDirection={"column"}
          alignItems={"center"}
          marginY={2}
        >
          <CircularProgress />
          {loadingText}
        </Grid>
      )}
    </Grid>
  );
};

export default OnPremTenantSelector;
