import { useMemo, useState } from "react";
import InputImage from "../input-image/InputImage";
import {
  Alert,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListSubheader,
  Typography,
} from "@mui/material";
import { DeviceInstallation } from "../../models";
import { saveStateType } from "../../models/types";

type props = {
  deviceInstallation: DeviceInstallation;
  images: File[];
  onImageAdded: (image: File) => void;
  onImageError: () => void;
  onImageStepSkipped: () => void;
};
export const ImageUploadStep = ({
  images,
  deviceInstallation,
  onImageAdded,
  onImageError,
  onImageStepSkipped,
}: props) => {
  const imageUploaded = useMemo(() => images.length > 0, [images]);
  const [saveState, setSaveState] = useState<saveStateType>("default");
  const previews = useMemo(
    () => images.map((image) => URL.createObjectURL(image)),
    [images]
  );
  const handleImageAdded = (image: File) => {
    onImageAdded(image);
  };

  const handleImageSave = (saveState: saveStateType) => {
    setSaveState(saveState);
    if (saveState === "error") onImageError();
  };

  return (
    <Grid
      container
      flexDirection={"column"}
      justifyContent={"space-between"}
      sx={{ height: "inherit" }}
      rowSpacing={1}
    >
      <Grid item>
        {saveState !== "error" && (
          <InputImage
            onUpload={handleImageAdded}
            onImageSave={handleImageSave}
            deviceInstallation={deviceInstallation}
          />
        )}
      </Grid>
      <Grid item marginTop={2}>
        {!imageUploaded && saveState !== "error" && (
          <Container>
            <Typography>
              The purpose of the picture is to help Resani understand the
              placement of the device.
            </Typography>
          </Container>
        )}
        {saveState === "error" && (
          <Alert severity="error">Unable to save image</Alert>
        )}
        {!imageUploaded && (
          <Grid container justifyContent={"center"}>
            <Button onClick={onImageStepSkipped}>
              {saveState === "error" ? `Ok` : `Can't take picture now`}
            </Button>
          </Grid>
        )}
        {imageUploaded && (
          <Container sx={{ overflow: "scroll", maxHeight: "40vh" }}>
            <List>
              <ListSubheader>Uploaded</ListSubheader>
              {previews.map((preview, ind) => {
                return (
                  <ListItem
                    key={preview}
                    sx={{
                      justifyContent: "center",
                      marginBottom: ind === previews.length - 1 ? 0 : 2,
                    }}
                    secondaryAction={<>{previews.length > 1 && `${ind + 1}`}</>}
                  >
                    <img
                      src={preview}
                      alt={`Uploaded ${preview}`}
                      style={{
                        height: `40%`,
                        width: `40%`,
                        objectFit: "cover",
                        border: "2px lightgray solid",
                      }}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Container>
        )}
      </Grid>
    </Grid>
  );
};
export default ImageUploadStep;
