import { ClickAwayListener, Tooltip } from "@mui/material";
import { useState } from "react";
import { useIsMobile } from "../../hooks/media-hooks/mediaHooks";

type props = {
  title: string;
  children: JSX.Element;
};

const ClickableTooltip = ({ title, children }: props) => {
  const [open, setOpen] = useState(false);
  const mobile = useIsMobile();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  if (mobile)
    return (
      <ClickAwayListener  onClickAway={handleTooltipClose}>
        <span onClick={handleTooltipOpen}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={title}
          >
            {children}
          </Tooltip>
        </span>
      </ClickAwayListener>
    );

  return <Tooltip title={title}>{children}</Tooltip>;
};

export default ClickableTooltip;
