import Keycloak, { KeycloakConfig, KeycloakProfile } from "keycloak-js";
import { createContext, useEffect, useRef, useState } from "react";
import {
	keycloakInitOptions,
	keycloakConfig,
} from "../config/authenticationConfig";
import {
	setAuthHeaderInAxios,
	setTenantHeaderInAxiosIfNotExists,
} from "../utilities/axios";
import {
	extractRealmFromUrl,
	getHostWithoutFirstSubdomain,
} from "../utilities/helpers";
import {
	StorageKeys,
	StorageService,
} from "../utilities/storage/local-storage";
import { config as envConfig } from "../config";

const realm = extractRealmFromUrl();
const host = getHostWithoutFirstSubdomain();

const config: KeycloakConfig = {
	realm: realm!,
	clientId: "admin-frontend",
	url: host.startsWith("localhost")
		? "https://auth.dev.resani.com/"
		: envConfig.keycloak.authServerUrl,
};

// TODO må hente denne tenanten via api
setTenantHeaderInAxiosIfNotExists(realm!);

const keycloak = new Keycloak(config);

keycloak.onTokenExpired = () => {
	keycloak
		.updateToken(30)
		.then((refreshed) => {
			if (refreshed && keycloak.token) {
				setAuthHeaderInAxios(keycloak.token);
			} else {
				keycloak.login();
			}
		})
		.catch(() => keycloak.login());
};

/**
 * AuthContextValues defines the structure for the default values of the {@link AuthContext}.
 */
export interface AuthContextValues {
	/**
	 * Whether or not a user is currently authenticated
	 */
	isAuthenticated: boolean;
	/**
	 * The name of the authenticated user
	 */
	username: string;
	/**
	 * The keycloak profile loaded after login
	 */
	profile: KeycloakProfile | null;

	/**
	 * The user info from user info endpoint
	 */
	userInfo: KeycloakProfile | null;

	/**
	 * Function to initiate the logout
	 */
	logout: () => void;
	/**
	 * Check if the user has the given role
	 */
	hasRealmRole: (role: string) => boolean;
	hasResourceRole: (role: string) => boolean;
	getToken: () => string | undefined;
}

/**
 * Default values for the {@link AuthContext}
 */
const defaultAuthContextValues: AuthContextValues = {
	isAuthenticated: false,
	username: "",
	userInfo: null,
	profile: null,
	logout: () => {},
	hasRealmRole: (role) => false,
	hasResourceRole: (role) => false,
	getToken: () => "",
};

/**
 * Create the AuthContext using the default values.
 */
export const AuthContext = createContext<AuthContextValues>(
	defaultAuthContextValues,
);

/**
 * The props that must be passed to create the {@link AuthContextProvider}.
 */
interface AuthContextProviderProps {
	/**
	 * The elements wrapped by the auth context.
	 */
	children: JSX.Element;
}

/**
 * AuthContextProvider is responsible for managing the authentication state of the current user.
 *
 * @param props
 */
const AuthContextProvider = (props: AuthContextProviderProps) => {
	// Create the local state in which we will keep track if a user is authenticated
	const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
	// Local state that will contain the users name once it is loaded
	const [username, setUsername] = useState<string>("");
	const [profile, setProfile] = useState<KeycloakProfile | null>(null);
	const [userInfo, setUserInfo] = useState<KeycloakProfile | null>(null);

	const didInit = useRef(false);

	// Effect used to initialize the Keycloak client. It has no dependencies so it is only rendered when the app is (re-)loaded.
	useEffect(() => {
		/**
		 * Initialize the Keycloak instance
		 */
		async function initializeKeycloak() {
			try {
				const isAuthenticatedResponse = await keycloak.init({
					...keycloakInitOptions,
					checkLoginIframe: false,
				});

				// If the authentication was not successfull the user is send back to the Keycloak login form
				if (!isAuthenticatedResponse) {
					keycloak.login();
				}

				// If we get here the user is authenticated and we can update the state accordingly
				setAuthenticated(isAuthenticatedResponse);
				setTenantHeaderInAxiosIfNotExists(keycloak.realm!);
				StorageService.setItem(StorageKeys.PartnerId, keycloak.realm!);
			} catch {
				setAuthenticated(false);
			}
		}

		if (didInit.current) {
			return;
		}

		didInit.current = true;

		initializeKeycloak();
	}, []);

	// This effect loads the users profile in order to extract the username
	useEffect(() => {
		/**
		 * Load the profile for of the user from Keycloak
		 */
		async function loadProfile() {
			try {
				const profile = await keycloak.loadUserProfile();
				if (profile.firstName) {
					setUsername(profile.firstName);
				} else if (profile.username) {
					setUsername(profile.username);
				}
				setProfile(profile);
			} catch {
				console.log("error trying to load the users profile");
			}
		}

		/**
		 * Load the user info for of the user from Keycloak
		 */
		async function loadUserInfo() {
			try {
				const userInfo = await keycloak.loadUserInfo();
				if (userInfo) {
					setUserInfo(userInfo);
				}
			} catch {
				console.log("error trying to load the users user info");
			}
		}

		// Only load the profile if a user is authenticated
		if (isAuthenticated) {
			loadProfile();
			loadUserInfo();
		}
	}, [isAuthenticated]);

	/**
	 * Initiate the logout
	 */
	const logout = () => {
		keycloak.logout();
	};

	/**
	 * Check if the user has the given role
	 * @param role to be checked
	 * @returns whether or not if the user has the role
	 */
	const hasResourceRole = (role: string) => {
		return keycloak.hasResourceRole(role);
	};

	const hasRealmRole = (role: string) => {
		return keycloak.hasRealmRole(role);
	};

	const getToken = () => keycloak.token;

	// Setup the context provider
	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				username,
				logout,
				profile,
				userInfo,
				hasResourceRole,
				hasRealmRole,
				getToken,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthContextProvider;
