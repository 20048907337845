import { useCallback, useEffect, useMemo, useState } from "react";
import { PaginationQuery } from "../../utilities/api";
import { fetchStateType } from "../../models/types";
import {
  getScreenUsers,
} from "../../utilities/axios/admin-api-calls/users-calls/usersCalls";

export const SCREEN_USER_NAME_PREFIX = "screen";
export const useAddScreenUser = () => {
  const [availableNumber, setAvailableScreenNumber] = useState(-1);

  const [fetchState, setFetchState] = useState<fetchStateType>("default");

  const internalGetScreenUsers = useCallback(
    async (page: number = 1, pageSize: number = 1) => {
      let screenUsersResp = await getScreenUsers(
        new PaginationQuery(page, pageSize)
      );

      return screenUsersResp;
    },
    []
  );

  const getNextAvailableScreenNumber = useCallback(async () => {
    if (fetchState !== "default") return;

    setFetchState("loading");

    var users = await internalGetScreenUsers();

    if (!users) {
      setFetchState("error");
      return;
    }

    if(users.items.length === 0){
      setAvailableScreenNumber(1)
      setFetchState("fetched")
      return;
    }

    let prevUser = users.items[0];

    let prevScreenNumber = extractNumberFromEmail(prevUser.email);


    if (!prevScreenNumber) {
      setFetchState("error");
      return;
    }
    setFetchState("fetched")

    setAvailableScreenNumber(prevScreenNumber + 1);
  }, [fetchState, internalGetScreenUsers]);

  useEffect(() => {
    if (fetchState === "default") {
      getNextAvailableScreenNumber();
    }
  }, [fetchState, getNextAvailableScreenNumber]);

  const userScreenName = useMemo(() => {
    if (fetchState !== "fetched" || availableNumber === -1) return null;

    return buildName(availableNumber);
  }, [fetchState, availableNumber]);

  const userEmail = useMemo(() => {
    if (userScreenName === null) return null;
    return `${userScreenName}@resani.com`;
  }, [userScreenName]);

  return {
    screenUsersCountFetchState: fetchState,
    userScreenName,
    userEmail,
  };
};

function buildName(count: number) {
  return `${SCREEN_USER_NAME_PREFIX}${count}`;
}
function extractNumberFromEmail(email: string): number | null {
  const match = email.match(/screen(\d+)@/);
  return match ? parseInt(match[1], 10) : null;
}
