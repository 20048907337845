import { Grid } from "@mui/material";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import {
	useIsMobile,
	useIsSmallScreen,
} from "../../../hooks/media-hooks/mediaHooks";
import { useTenant } from "../../../hooks/tenant";
import { resetDetailedDeviceState } from "../../../store/actions/detailedDeviceActions";
import { RootState } from "../../../store/reducers";
import DeviceNotFound from "../../device-details/DeviceNotFound";
import Linkage from "../../link/Linkage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useMaintenance } from "../../../hooks/maintenance-hooks/maintenanceHook";
import { RouteObj } from "../../../routing/routes";
import { getSimpleDeviceById } from "../../../utilities/axios/admin-api-calls/adminCalls";
import { Device } from "../../../models";

const DeviceAsserter = () => {
	const { deviceId } = useParams<any>();
	const tenant = useTenant();
	const dispatch = useDispatch();
	const mobile = useIsMobile();
	const maintenance = useMaintenance();
	const smallscreen = useIsSmallScreen();
	const [adminDevice, setAdminDevice] = useState<Device | null>(null);
	const { fetchState } = useSelector(
		(state: RootState) => state.detailedDeviceReducer,
	);
	const link: RouteObj = useMemo(() => {
		if (
			maintenance.maintenanceModeActive &&
			maintenance.currentlyAtServiceTenant
		)
			return { name: maintenance.tenant?.name ?? "", path: `/dashboard` };

		return {
			path: `/dashboard`,
			name: tenant?.name ?? "",
		};
	}, [tenant, maintenance]);

	const fetchAdminDevice = useCallback(async () => {
		if (!deviceId) return;

		let adminDevicefetched = await getSimpleDeviceById(deviceId);

		if (adminDevicefetched) {
			setAdminDevice(adminDevicefetched);
		}
	}, [deviceId]);

	useEffect(() => {
		if (tenant?.identifier && deviceId) {
			dispatch(resetDetailedDeviceState());
		}
	}, [tenant?.identifier, deviceId, dispatch]);

	useEffect(() => {
		fetchAdminDevice();
	}, [fetchAdminDevice]);

	return (
		<div
			key={`${deviceId ?? "unknown"}_${tenant?.identifier ?? "unknownTenant"}`}
		>
			<Grid container marginTop={2} flexDirection="column">
				<Grid item marginLeft={2}>
					<Linkage to={link.path}>
						<ArrowBackIcon fontSize="small" /> {link.name} devices
					</Linkage>
				</Grid>
				<Grid
					item
					textAlign={smallscreen ? "center" : "inherit"}
					marginLeft={mobile ? 0 : 2}
				>
					<h1>{deviceId}</h1>
				</Grid>
			</Grid>
			{fetchState !== "notFound" ? (
				<Outlet />
			) : (
				<DeviceNotFound
					deviceId={deviceId}
					tenant={adminDevice?.location.tenant ?? null}
				/>
			)}
		</div>
	);
};

export default DeviceAsserter;
