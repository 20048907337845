import { useParams } from "react-router-dom";
import Title from "../../components/title/Title";
import { useCompleteInstallation } from "../../hooks/device-installations/deviceInstallationHooks";
import { CompleteInstallDto, DeviceInstallation } from "../../models";
import InstallationStepper from "../../components/installations/InstallationStepper";
import { Alert, Button, CircularProgress, Grid } from "@mui/material";
import { useCallback } from "react";
import { useAdminDeviceNavigation } from "../../hooks/location-hooks/navigateToAdminDeviceHook";

const InstallDeviceView = () => {
  const { serialNumber } = useParams();
  const navigateToDevice = useAdminDeviceNavigation();

  const { saveStatus: completeInstallState, complete: completeInstall } =
    useCompleteInstallation();

  const handleConfirm = useCallback(
    (
      completeInstallDto: CompleteInstallDto,
      deviceInstallation: DeviceInstallation
    ) => {
      if (!deviceInstallation) return;

      completeInstall(completeInstallDto, deviceInstallation);
    },
    [completeInstall]
  );

  if (!serialNumber) return <>Error no serial number</>;

  return (
    <>
      <Title>Install {serialNumber}</Title>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        {completeInstallState === "default" && (
          <InstallationStepper onConfirm={handleConfirm} />
        )}
      </Grid>

      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        spacing={3}
        marginTop={2}
      >
        {completeInstallState === "loading" && (
          <Grid item justifySelf={"center"}>
            <CircularProgress />
          </Grid>
        )}

        {completeInstallState === "success" && (
          <>
            <Grid item>
              <Alert severity="success">
                Successfully installed {serialNumber}
              </Alert>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => navigateToDevice(serialNumber)}
              >
                Go to device
              </Button>
            </Grid>
          </>
        )}

        {completeInstallState === "error" && (
          <Grid item justifySelf={"center"}>
            <Alert severity="error">Install failed {serialNumber}</Alert>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default InstallDeviceView;
