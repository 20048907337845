import { AnyAction, Reducer } from "redux";
import {
  RESET_DEVICE_FILTER_STATE,
  UPDATE_POWER_FILTER_VALUE,
  INCLUDE_FLOOR_DEVICE_FILTER,
  INCLUDE_SECTION_DEVICE_FILTER,
  INCLUDE_ROOM_DEVICE_FILTER,
  EXCLUDE_ROOM_DEVICE_FILTER,
  REMOVE_ROOM_DEVICE_FILTER,
  REMOVE_SECTION_DEVICE_FILTER,
  REMOVE_FLOOR_DEVICE_FILTER,
  EXCLUDE_FLOOR_DEVICE_FILTER,
  INCLUDE_ROOM_TYPE_DEVICE_FILTER,
  EXCLUDE_ROOM_TYPE_DEVICE_FILTER,
  UPDATE_DEVICE_FILTER_TENANT,
} from "../actions/actionTypes";
import { Tenant } from "../../models";

export type powerFilterValue = "default" | "On" | "Off";

interface LocationFilter {
  sections: {
    included: { [id: string]: boolean };
  };
  floors: {
    included: { [id: string]: boolean };
  };
  rooms: {
    included: { [id: string]: boolean };
  };
}

interface RoomTypeFilter {
  included: { [id: string]: boolean };
}

type deviceFilterType = {
  powerFilterValue: powerFilterValue;
  locationFilter: LocationFilter;
  roomTypeFilter: RoomTypeFilter;
  filterTenant: Tenant | null
};

const initialState: deviceFilterType = {
  powerFilterValue: "default",
  locationFilter: {
    sections: { included: {} },
    floors: { included: {} },
    rooms: { included: {} },
  },
  roomTypeFilter: { included: {} },
  filterTenant: null
};

const deviceFilterReducer: Reducer<deviceFilterType, AnyAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case INCLUDE_FLOOR_DEVICE_FILTER:
      return {
        ...state,

        locationFilter: {
          ...state.locationFilter,
          floors: {
            ...state.locationFilter.floors,
            included: {
              ...state.locationFilter.floors.included,
              [action.payload]: true,
            },
          },
        },
      };
    case INCLUDE_ROOM_DEVICE_FILTER:
      return {
        ...state,
        locationFilter: {
          ...state.locationFilter,
          rooms: {
            ...state.locationFilter.rooms,
            included: {
              ...state.locationFilter.rooms.included,
              [action.payload]: true,
            },
          },
        },
      };
    case INCLUDE_SECTION_DEVICE_FILTER:
      return {
        ...state,
        locationFilter: {
          ...state.locationFilter,
          sections: {
            ...state.locationFilter.sections,
            included: {
              ...state.locationFilter.sections.included,
              [action.payload]: true,
            },
          },
        },
      };
    case INCLUDE_ROOM_TYPE_DEVICE_FILTER:
      return {
        ...state,
        roomTypeFilter: {
          included: {
            ...state.roomTypeFilter.included,
            [action.payload]: true,
          },
        },
      };
    case EXCLUDE_ROOM_DEVICE_FILTER:
      return {
        ...state,
        locationFilter: {
          ...state.locationFilter,
          rooms: {
            ...state.locationFilter.rooms,
            included: {
              ...state.locationFilter.rooms.included,
              [action.payload]: false,
            },
          },
        },
      };
    case EXCLUDE_FLOOR_DEVICE_FILTER:
      return {
        ...state,
        locationFilter: {
          ...state.locationFilter,
          floors: {
            ...state.locationFilter.floors,
            included: {
              ...state.locationFilter.floors.included,
              [action.payload]: false,
            },
          },
        },
      };
    case EXCLUDE_ROOM_TYPE_DEVICE_FILTER:
      return {
        ...state,
        roomTypeFilter: {
          included: {
            ...state.roomTypeFilter.included,
            [action.payload]: false,
          },
        },
      };
    case REMOVE_ROOM_DEVICE_FILTER:
      const { [action.payload]: roo_, ...remainingRoomsIncluded } =
        state.locationFilter.rooms.included;
      return {
        ...state,
        locationFilter: {
          ...state.locationFilter,
          rooms: {
            ...state.locationFilter.rooms,
            included: remainingRoomsIncluded,
          },
        },
      };
    case REMOVE_SECTION_DEVICE_FILTER:
      const { [action.payload]: sec_, ...remainingSectionsIncluded } =
        state.locationFilter.sections.included;
      return {
        ...state,
        locationFilter: {
          ...state.locationFilter,
          sections: {
            ...state.locationFilter.sections,
            included: remainingSectionsIncluded,
          },
        },
      };
    case REMOVE_FLOOR_DEVICE_FILTER:
      const { [action.payload]: flo_, ...remainingFloorsIncluded } =
        state.locationFilter.floors.included;
      return {
        ...state,
        locationFilter: {
          ...state.locationFilter,
          floors: {
            ...state.locationFilter.floors,
            included: remainingFloorsIncluded,
          },
        },
      };
    case UPDATE_POWER_FILTER_VALUE:
      return { ...state, powerFilterValue: action.payload };
    case UPDATE_DEVICE_FILTER_TENANT:
      return { ...state, filterTenant: action.payload };
    case RESET_DEVICE_FILTER_STATE:
      return initialState;
    default:
      return state;
  }
};

export default deviceFilterReducer;
