import RoomsDisplay from "../rooms-display/RoomsDisplay";
import {
    Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import {
  RoomModel,
  SimpleFloor,
} from "../../models/locations.model";
import { useMemo, useState } from "react";
import {
  getPrevInstallFromStorage,
  getPrevInstallRoomFromStorage,
} from "../../utilities/storage/prevDeviceInstallStorage";
import { useTenant } from "../../hooks/tenant";
import FloorsDisplay from "../floors-display/FloorsDisplay";
import AddIcon from "@mui/icons-material/Add";
type props = {
  onRoomChosen: (room: RoomModel) => void;
};

const RoomSelect = ({ onRoomChosen }: props) => {
  const prevInstall = getPrevInstallFromStorage();
  const prevInstallFloor = getPrevInstallRoomFromStorage()?.floor;

  const tenant = useTenant();

  const [dialogOpen, setDialogOpen] = useState(false);

  const shouldShowPrevFloor = useMemo(
    () =>
      tenant?.identifier === prevInstall?.tenant.identifier &&
      !!prevInstallFloor,
    [tenant, prevInstall, prevInstallFloor]
  );

  const [floors, setFloors] = useState<SimpleFloor[]>(
    shouldShowPrevFloor ? [prevInstallFloor!] : []
  );

  const handleRemove = (floorId: string) => {
    setFloors((prev) => prev.filter((f) => f.id !== floorId));
  };

  const addToFloors = (floor: SimpleFloor) => {
    setFloors((prev) => [...prev, floor]);
    handleDialogClose();
  };

  const handleFilterButtonClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => setDialogOpen(false);
  return (
    <>

      {floors.map((floor) => {
        return (
          <Chip
            key={floor.id}
            color={"secondary"}
            label={`${floor!.name}`}
            onClick={() => handleRemove(floor.id)}
            onDelete={() => handleRemove(floor.id)}
          />
        );
      })}

      {floors.length === 0 &&
      <Box sx={{marginRight:1, alignContent: "center"}}>
      <i>Not filtering rooms on floors</i>
      </Box>}

      {floors.length === 0 ?
      <Button onClick={handleFilterButtonClick}>Filter</Button>:
        <IconButton onClick={handleFilterButtonClick} sx={{marginLeft: 1}}>
          <AddIcon />
        </IconButton>}

      <RoomsDisplay
        handleRoomChosen={onRoomChosen}
        floorIds={floors.map((f) => f.id)}
      />

      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>
          Choose a floor to filter the rooms on
        </DialogTitle>
        <DialogContent>
          <FloorsDisplay
            maxItemsPerPage={6}
            onFloorChosen={addToFloors}
            disabledFloors={floors.map((f) => f.id)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RoomSelect;
