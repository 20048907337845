import { useState, useEffect, useContext, useRef, useCallback } from "react";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";
import { AuthContext } from "../../provider/AuthContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { setConnectionIdFetchState } from "../../store/actions/signalrActions";
import { RootState } from "../../store/reducers";
import { config, signalRFunctions } from "../../config";
import { useIsAuthenticated } from "../authentication-hooks/authenticationHooks";
import { addEvent } from "../../store/actions/eventActions";
import { parseSignalREvent } from "../../utilities/events/eventUtils";

const hubUrl = `${config.signalR.url}/signalR`;

const useInitBroadcaster = () => {
  const hasInitiated = useRef(false);
  const dispatch = useDispatch();
  const connectionIdState = useSelector(
    (state: RootState) => state.signalrReducer.connectionIdFetchState
  );

  const authContext = useContext(AuthContext);
  const { authDone } = useIsAuthenticated();

  const [connection, setConnection] = useState<HubConnection | null>(null);

  const { addEventCallbacks, callbacksAdded } = useEventCallbacks();

  useEffect(() => {
    const buildConnection = () =>
      new HubConnectionBuilder()
        .configureLogging(LogLevel.Critical)
        .withUrl(hubUrl, {
          accessTokenFactory: async () => authContext.getToken() ?? "",
        })
        .withAutomaticReconnect()
        .build();

    const establishConnection = async () => {
      let newConnection = buildConnection();

      if (
        newConnection.state === HubConnectionState.Connected ||
        newConnection.state === HubConnectionState.Connecting ||
        newConnection.state === HubConnectionState.Reconnecting
      )
        return;
      dispatch(setConnectionIdFetchState("loading"));

      try {
        await newConnection.start();
        console.log("SignalR connected:", newConnection.connectionId);
        dispatch(setConnectionIdFetchState("fetched"));
        setConnection(newConnection);
      } catch (error) {
        console.error("SignalR connection error:", error);
        dispatch(setConnectionIdFetchState("error"));
      }
    };

    if (authDone && !hasInitiated.current) {
      establishConnection();
      hasInitiated.current = true;
    }
  }, [authDone, authContext, dispatch]);

  useEffect(() => {
    if (
      connectionIdState === "fetched" &&
      connection?.state === HubConnectionState.Connected &&
      !callbacksAdded
    ) {
      addEventCallbacks(connection);
    }
  }, [connectionIdState, addEventCallbacks, connection, callbacksAdded]);

  return { connection, addEventCallbacks, isConnected: callbacksAdded };
};

export default useInitBroadcaster;

const useEventCallbacks = () => {
  const dispatch = useDispatch();

  const [callbacksAdded, setCallbacksAdded] = useState(false);
  const connectionIdState = useSelector(
    (state: RootState) => state.signalrReducer.connectionIdFetchState
  );

  const addEventCallbacks = useCallback(
    (connection: HubConnection) => {
      if (!callbacksAdded && connectionIdState === "fetched") {
        for (const [, value] of Object.entries(signalRFunctions.device)) {
          connection.on(value, (message) => {
            // console.log(`Received ${value}`);

            let receivedEvent = parseSignalREvent(value, message);
            dispatch(addEvent(receivedEvent));
          });
          // console.log(`Added ${value} callback`);
          
        }
        setCallbacksAdded(true);
      }
    },
    [callbacksAdded, connectionIdState, dispatch]
  );

  return { addEventCallbacks, callbacksAdded };
};
