import { Tenant } from "../models";

export const pathToTenantDashboard = (tenant: Tenant) => `/dashboard`;
export const pathToTenantUsers = (tenant: Tenant | null) => `/users`;

export const pathToTenantDevice = (
	tenantIdentfier: string,
	serialNumber: string,
) => `/device/${serialNumber}`;
export const pathToAdminDevice = (deviceId: string) => `/devices/${deviceId}`;

export const pathToTenantInstall = (tenantIdentfier: string) =>
	`/installations`;
