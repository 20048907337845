import { useCallback, useEffect, useState } from "react";
import { fetchStateType, saveStateType } from "../../models/types";
import {
  completeInstall,
  createDeviceInstallation,
  getAllDeviceInstallations,
  getDeviceInstallations,
  updateInstaller,
  updateStatus,
} from "../../utilities/axios/admin-api-calls/tenant-calls/installationCalls";
import { PagedResponse, PaginationQuery } from "../../utilities/api";
import {
  CompleteInstallDto,
  DeviceInstallation,
  DeviceInstallationStatus,
  Tenant,
} from "../../models";

export const useDeviceInstallationsForDevice = (
  serialNumber: string,
  statusFilter: DeviceInstallationStatus[] | null = null
) => {
  const [fetchState, setFetchState] = useState<fetchStateType>("default");
  const [currentPage, setCurrentPage] = useState(1);
  const [mostRecent, setMostRecent] = useState<DeviceInstallation | null>(null);
  const [deviceInstallations, setDeviceInstallations] =
    useState<PagedResponse<DeviceInstallation>>();
  const [saveState, setSaveState] = useState<saveStateType>("default");
  const [abortSaveStatus, setAbortSaveStatus] =
    useState<saveStateType>("default");

  const [images, setImages] = useState<File[]>([]);
  const [anyImages, setAnyImages] = useState(false);

  useEffect(() => {
    if (mostRecent) {
      setAnyImages(mostRecent.images.length > 0);
    }
  }, [mostRecent]);

  const abortInstall = useCallback(
    async (installation: DeviceInstallation) => {
      if (abortSaveStatus === "loading") return false;
      if (
        installation.installationStatus !== DeviceInstallationStatus.READY &&
        installation.installationStatus !== DeviceInstallationStatus.IN_PROGRESS
      )
        return false;

      setAbortSaveStatus("loading");

      const newDeviceInst = await updateStatus(
        installation.id,
        DeviceInstallationStatus.ABORTED
      );

      if (!newDeviceInst) {
        setAbortSaveStatus("error");
        return false;
      }

      setAbortSaveStatus("success");
      return true;
    },
    [abortSaveStatus]
  );

  const makeInstallReady = useCallback(
    async (serialNumber: string, tenant: Tenant) => {
      if (saveState === "loading") return false;
      setSaveState("loading");
      const deviceInstallation = await createDeviceInstallation(
        serialNumber,
        tenant.identifier
      );

      if (deviceInstallation === 409) {
        setSaveState("conflict");
        return false;
      }

      if (deviceInstallation === 500) {
        setSaveState("error");
        return false;
      }

      setSaveState("success");
      return true;
    },
    [saveState]
  );

  const fetchDeviceInstallations = useCallback(async () => {
    if (fetchState !== "default") return;

    setFetchState("loading");

    const deviceInstallations = await getDeviceInstallations(
      serialNumber,
      new PaginationQuery(currentPage, 20),
      null,
      statusFilter
    );

    if (!deviceInstallations) {
      setFetchState("error");
      return;
    }

    setDeviceInstallations(deviceInstallations);
    setFetchState("fetched");
    if (currentPage === 1 && deviceInstallations.items.length > 0)
      setMostRecent(deviceInstallations.items[0]);
  }, [fetchState, currentPage, serialNumber, statusFilter]);

  const refetch = useCallback(() => {
    setFetchState("default");
    fetchDeviceInstallations();
  }, [fetchDeviceInstallations]);

  const switchTenantInstall = useCallback(
    async (tenant: Tenant) => {
      if (mostRecent && mostRecent?.tenant.identifier === tenant.identifier)
        return;

      if (mostRecent) {
        await abortInstall(mostRecent);
      }

      let ready = await makeInstallReady(serialNumber, tenant);

      if (ready) refetch();
    },
    [refetch, serialNumber, abortInstall, makeInstallReady, mostRecent]
  );

  const handleImageAdded = (image: File) => {
    setImages((prev) => [...prev, image]);
    setAnyImages(true);
  };

  const resetImages = () => {
    setImages([]);
    setAnyImages(false);
  }
  fetchDeviceInstallations();
  return {
    switchTenantInstall,
    mostRecent,
    fetchState,
    deviceInstallations,
    currentPage,
    setCurrentPage,
    refetch,
    images,
    handleImageAdded,
    anyImages,
    resetImages
  };
};

export const useDeviceInstallations = (
  statusFilter: DeviceInstallationStatus[] | null = null,
  tenantIdentifierFilter: string[] | null = null
) => {
  const [fetchState, setFetchState] = useState<fetchStateType>("default");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(7);
  const [mostRecent, setMostRecent] = useState<DeviceInstallation | null>(null);
  const [deviceInstallations, setDeviceInstallations] =
    useState<PagedResponse<DeviceInstallation>>();

  const fetchDeviceInstallations = useCallback(async () => {
    if (fetchState !== "default") return;

    setFetchState("loading");

    const deviceInstallations = await getAllDeviceInstallations(
      new PaginationQuery(currentPage, itemsPerPage),
      tenantIdentifierFilter,
      statusFilter
    );

    if (!deviceInstallations) {
      setFetchState("error");
      return;
    }

    setDeviceInstallations(deviceInstallations);
    setFetchState("fetched");
    if (currentPage === 1 && deviceInstallations.items.length > 0)
      setMostRecent(deviceInstallations.items[0]);
  }, [
    fetchState,
    currentPage,
    itemsPerPage,
    statusFilter,
    tenantIdentifierFilter,
  ]);

  const refetch = useCallback(() => {

    setFetchState("default");
    fetchDeviceInstallations();
  }, [fetchDeviceInstallations]);

  fetchDeviceInstallations();

  return {
    mostRecent,
    fetchState,
    deviceInstallations,
    currentPage,
    itemsPerPage,
    setItemsPerPage,
    setCurrentPage,
    refetch,
  };
};

export type DeviceInstallationsManagement = ReturnType<
  typeof useDeviceInstallations
>;

export const useMakeDeviceInstallReady = () => {
  const [saveState, setSaveState] = useState<saveStateType>("default");
  const [mostRecent, setMostRecent] = useState<string | null>(null);

  const makeInstallReady = useCallback(
    async (serialNumber: string, tenant: Tenant) => {
      setMostRecent(serialNumber);
      if (saveState !== "default") return false;
      const deviceInstallation = await createDeviceInstallation(
        serialNumber,
        tenant.identifier
      );

      if (deviceInstallation === 409) {
        setSaveState("conflict");
        return false;
      }

      if (deviceInstallation === 500) {
        setSaveState("error");
        return false;
      }

      setSaveState("success");
      return true;
    },
    [saveState]
  );

  return {
    saveState,
    setSaveState,
    makeInstallReady,
    mostRecent,
  };
};

export type DeviceInstallationReadyer = ReturnType<
  typeof useMakeDeviceInstallReady
>;

export const useDeviceStatusAbort = () => {
  const [saveStatus, setSaveStatus] = useState<saveStateType>("default");

  const abortInstall = useCallback(async (installation: DeviceInstallation) => {
    if (
      installation.installationStatus !== DeviceInstallationStatus.READY &&
      installation.installationStatus !== DeviceInstallationStatus.IN_PROGRESS
    )
      return;

    setSaveStatus("loading");

    const newDeviceInst = await updateStatus(
      installation.id,
      DeviceInstallationStatus.ABORTED
    );

    if (!newDeviceInst) {
      setSaveStatus("error");
      return;
    }

    setSaveStatus("success");
  }, []);

  return {
    saveStatus,
    setSaveStatus,
    abortInstall,
  };
};

export type DeviceInstallationAborter = ReturnType<typeof useDeviceStatusAbort>;

export const useUpdateDeviceStatus = (
  deviceInstallation: DeviceInstallation | null
) => {
  const [saveStatus, setSaveStatus] = useState<saveStateType>("default");

  const updateDeviceInstallationStatus = useCallback(
    async (newStatus: DeviceInstallationStatus) => {
      if (!deviceInstallation || saveStatus !== "default") return;

      setSaveStatus("loading");
      var res = await updateStatus(deviceInstallation.id, newStatus);

      if (!res) {
        setSaveStatus("error");
        return;
      }
      setSaveStatus("success");
    },
    [deviceInstallation, saveStatus]
  );

  const resetUpdate = () => setSaveStatus("default");

  return {
    saveStatus,
    update: updateDeviceInstallationStatus,
    resetUpdate,
  };
};

export type DeviceInstallationStatusUpdater = ReturnType<
  typeof useUpdateDeviceStatus
>;

export const useUpdateDeviceInstaller = (
  deviceInstallation: DeviceInstallation | null
) => {
  const [saveStatus, setSaveStatus] = useState<saveStateType>("default");

  const update = useCallback(async () => {
    if (!deviceInstallation || saveStatus !== "default") return;

    setSaveStatus("loading");
    var res = await updateInstaller(deviceInstallation.id);

    if (!res) {
      setSaveStatus("error");
      return;
    }
    setSaveStatus("success");
  }, [deviceInstallation, saveStatus]);

  const resetUpdate = () => setSaveStatus("default");

  return {
    saveStatus,
    update: update,
    resetUpdate,
  };
};

export type DeviceInstallerUpdater = ReturnType<
  typeof useUpdateDeviceInstaller
>;

export const useCompleteInstallation = () => {
  const [saveStatus, setSaveStatus] = useState<saveStateType>("default");

  const complete = useCallback(
    async (
      completeInstallDto: CompleteInstallDto,
      deviceInstallation: DeviceInstallation
    ) => {
      if (!deviceInstallation || saveStatus !== "default") return;

      setSaveStatus("loading");
      var res = await completeInstall(
        deviceInstallation.id,
        completeInstallDto
      );

      if (!res) {
        setSaveStatus("error");
        return;
      }
      setSaveStatus("success");
    },
    [saveStatus]
  );

  const resetUpdate = () => setSaveStatus("default");

  return {
    saveStatus,
    complete,
    resetUpdate,
  };
};

export type DeviceInstallationCompleter = ReturnType<
  typeof useCompleteInstallation
>;
