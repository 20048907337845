import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Switch,
  TextField,
} from "@mui/material";
import { useAddScreenUser } from "../../hooks/user-hooks/screenUserHooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import config, { ScreenTypeOption } from "../../config/config";
import { createScreenUser } from "../../utilities/axios/admin-api-calls/users-calls/usersCalls";
import { saveStateType } from "../../models/types";
import { useNavigate } from "react-router-dom";
import { pathToTenantUsers } from "../../utilities/paths";
import { useTenant } from "../../hooks/tenant";
import UserSectionComboBox from "../user-section-combobox/UserSectionComboBox";
import { UserSection } from "../../models/users.model";
import {useTenantNavigate} from '../../utilities/helpers';

const AddScreenUserForm = () => {
  const screenUserAddForm = useAddScreenUser();
  const [selectedScreenTypeOption, setSelectedScreenTypeOption] =
    useState<ScreenTypeOption | null>(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [tempPassword, setTempPassword] = useState(true)
  const [sections, setSections] = useState<UserSection[]>([])

  const [creationSaveState, setCreationSaveState] =
    useState<saveStateType>("default");

  const formReady = useMemo(() => {
    return (
      firstName.length > 0 &&
      lastName.length > 0 &&
      (screenUserAddForm.userEmail?.length ?? 0) > 0 &&
      password.length > 0
    );
  }, [firstName, lastName, screenUserAddForm,password]);

  const createScreen = useCallback(async () => {
    if (
      creationSaveState !== "default" ||
      !formReady ||
      !screenUserAddForm.userScreenName ||
      !screenUserAddForm.userEmail
    )
      return;
    setCreationSaveState("loading");

    let resp = await createScreenUser({
      email: screenUserAddForm.userEmail,
      screenDeviceType: screenUserAddForm.userScreenName,
      specs: firstName + " " + lastName,
      allowedSections: sections.map(s => s.id),
      passwordInfo:
        password.length > 0
          ? {
              password: password,
              temporary: tempPassword,
            }
          : undefined,
    });

    if (resp < 300) {
      setCreationSaveState("success");
      return;
    }

    setCreationSaveState("error");
  }, [
    firstName,
    sections,
    lastName,
    screenUserAddForm,
    password,
    tempPassword,
    formReady,
    creationSaveState,
  ]);

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleTempPasswordChange = () => setTempPassword((temp) => !temp)

  useEffect(() => {
    if (!selectedScreenTypeOption) {
      setFirstName("");
      setLastName("");
      return;
    }

    setFirstName(selectedScreenTypeOption.firstName);
    setLastName(selectedScreenTypeOption.lastName);
  }, [selectedScreenTypeOption]);

  return (
    <Grid container justifyContent={"center"}>
      {(screenUserAddForm.screenUsersCountFetchState === "loading" ||
        screenUserAddForm.screenUsersCountFetchState === "default" ||
        creationSaveState === "loading") && (
        <Skeleton variant="rectangular" height={500} width={400} />
      )}

      {/* {JSON.stringify(screenUserAddForm)} */}
      {screenUserAddForm.screenUsersCountFetchState === "error" && (
        <Alert severity="error">Error when contacting api</Alert>
      )}

      {creationSaveState === "success" && (
        <UserCreated userEmail={screenUserAddForm.userEmail} />
      )}

      {creationSaveState === "error" && (
        <Alert severity="error">Unable to create screen user</Alert>
      )}
      {screenUserAddForm.userEmail &&
        screenUserAddForm.screenUsersCountFetchState === "fetched" &&
        creationSaveState === "default" && (
          <Grid
            container
            width={400}
            justifyContent={"center"}
            spacing={2}
            marginTop={2}
            flexDirection={"column"}
          >
            Email
            <Grid item>
              <TextField
                fullWidth={true}
                InputProps={{
                  readOnly: true,
                }}
                defaultValue={screenUserAddForm.userEmail}
              />
            </Grid>
            Prefilled screen types
            <Grid item>
              <ScreenTypeSelect
                currentOption={selectedScreenTypeOption}
                options={config.screenUsers.screenOptions}
                onChange={(opt: ScreenTypeOption | null) =>
                  setSelectedScreenTypeOption(opt)
                }
              />
            </Grid>
            Manual info
            <Grid item>
              <TextField
                fullWidth={true}
                onChange={handleFirstNameChange}
                value={firstName}
                label="Device Type"
                required
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth={true}
                onChange={handleLastNameChange}
                value={lastName}
                required
                label="Model, year, specs"
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth={true}
                required
                label="Password"
                type="password"
                autoComplete="current-password"
                onChange={handlePasswordChange}
              />
              Temporary password
              <Switch
              checked={tempPassword}
            onChange={handleTempPasswordChange} /> {tempPassword ? `Yes`: `No`}
            </Grid>
            <Grid item>
            <UserSectionComboBox sections={sections} onSectionsChosen={setSections}  disabled={false}/>

            </Grid>
            <Grid item alignSelf={"center"}>
              <Button
                variant="outlined"
                disabled={!formReady}
                onClick={createScreen}
              >
                Create screen user
              </Button>
            </Grid>
          </Grid>
        )}
    </Grid>
  );
};

export default AddScreenUserForm;

type screenTypeSelectProps = {
  currentOption: ScreenTypeOption | null;
  options: ScreenTypeOption[];
  onChange: (selected: ScreenTypeOption | null) => void;
};
const ScreenTypeSelect = ({
  currentOption,
  options,
  onChange,
}: screenTypeSelectProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    let index = parseInt(event.target.value);
    if (index === -1) {
      onChange(null);
      return;
    }
    onChange(options[index]);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Screen Type</InputLabel>
        <Select
          value={currentOption?.displayName}
          label="Screen Type"
          onChange={handleChange}
        >
          <MenuItem value={-1}>
            <em>None</em>
          </MenuItem>
          {options.map((opt, i) => (
            <MenuItem key={opt.displayName} value={i}>
              {opt.displayName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

type userCreatedProps = {
  userEmail: string | null;
};
const UserCreated = ({ userEmail }: userCreatedProps) => {
  const navigate = useTenantNavigate();
  const tenant = useTenant();

  return (
    <Grid
      container
      width={400}
      justifyContent={"center"}
      spacing={2}
      marginTop={2}
      flexDirection={"column"}
    >
      <Grid item>
        <Alert severity="success">
          Screen user <b>{userEmail}</b> created
        </Alert>
      </Grid>
      <Grid item alignSelf={"center"} marginTop={4}>
        <Button variant="outlined" onClick={() => navigate(0)}>
          Create new static screen user
        </Button>
      </Grid>
      <Grid item alignSelf={"center"}>
        <Button
          variant="outlined"
          onClick={() => navigate(pathToTenantUsers(tenant))}
        >
          Back to users
        </Button>
      </Grid>
    </Grid>
  );
};
