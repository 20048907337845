import { Grid, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAdminDevice } from "../../hooks/admin-device/useAdminDeviceHooks";
import { useCallback, useEffect, useMemo } from "react";
import { pathToTenantDevice } from "../../utilities/paths";
import { useServiceTenant } from "../../hooks/tenant";
import Linkage from "../../components/link/Linkage";
import {
	setDetailedDevice,
	setFetchStateDetailedDevice,
} from "../../store/actions/detailedDeviceActions";
import { useDispatch } from "react-redux";
import { useTenantNavigate } from "../../utilities/helpers";

const DeviceLander = () => {
	const { serialNumber } = useParams();
	const { adminDevice: device } = useAdminDevice(serialNumber);
	const dispatch = useDispatch();

	const navigate = useTenantNavigate();
	const serviceTenant = useServiceTenant();
	const { state: navigationState } = useLocation();
	const isInternalNavigation = useMemo(() => {
		if (!navigationState) return false;

		if (!navigationState.internal) return false;

		return navigationState.internal;
	}, [navigationState]);

	const tenantPath = useMemo(() => {
		if (!device) return null;

		return pathToTenantDevice(
			device.location.tenant.identifier,
			device.serialNumber,
		);
	}, [device]);

	const deviceIsAtCustomerTenant = useMemo(() => {
		if (!serviceTenant.tenant) return false;

		return (
			device?.location.tenant.identifier !== serviceTenant.tenant?.identifier
		);
	}, [device, serviceTenant]);

	const redirectToTenant = useCallback(() => {
		if (!tenantPath || !device) return;

		dispatch(setDetailedDevice(device));
		dispatch(setFetchStateDetailedDevice("fetched"));
		navigate(tenantPath, {
			replace: true,
		});
	}, [tenantPath, navigate, dispatch, device]);

	useEffect(() => {
		// Redirect if this device has been assigned to a tenant that is not the service tenant
		if (
			tenantPath &&
			deviceIsAtCustomerTenant &&
			isInternalNavigation &&
			device
		)
			redirectToTenant();
	}, [
		device,
		tenantPath,
		deviceIsAtCustomerTenant,
		redirectToTenant,
		isInternalNavigation,
	]);

	return (
		<>
			{tenantPath && device && (
				<>
					<Grid container flexDirection={"column"} alignContent={"center"}>
						<Grid
							container
							flexDirection={"column"}
							alignContent={"center"}
							marginTop={4}
							rowSpacing={3}
						>
							<Grid item>
								<Linkage to="install">
									<Typography variant="h1" textAlign={"center"}>
										Install {device?.serialNumber}
									</Typography>
								</Linkage>
							</Grid>
							<Grid item>
								<Typography variant="h1" textAlign={"center"} color={"gray"}>
									OR
								</Typography>
							</Grid>
							<Grid item>
								<Linkage to={tenantPath}>
									<Typography variant="h1" textAlign={"center"}>
										Go to device page
									</Typography>
								</Linkage>
							</Grid>
						</Grid>
					</Grid>
				</>
			)}
		</>
	);
};

export default DeviceLander;
