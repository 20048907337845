import { useCallback, useMemo, useState } from "react";
import { fetchStateType } from "../../models/types";

export const useUserPosition = () => {
	const [position, setPosition] = useState<GeolocationPosition | null>(null);
	const [fetchState, setFetchState] = useState<fetchStateType>("default");
	const hasQueried = useMemo(() => fetchState !== "default", [fetchState]);

	const updatePosition = useCallback(() => {
		if (!!position) return;
		setFetchState("loading");

		navigator.geolocation.getCurrentPosition(
			(pos) => {
				setFetchState("fetched");
				setPosition(pos);
			},
			(error) => {
				setFetchState("error");
			},
			{
				timeout: 15_000,
			},
		);
	}, [position]);

	return {
		canQuery: !!navigator.geolocation,
		position,
		queryPosition: updatePosition,
		fetchState,
		hasQueried,
	};
};

export type UseUserPosition = ReturnType<typeof useUserPosition>;
