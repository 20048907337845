import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import useProvisioningForm, {
  useSessionStorageProvisionResult,
} from "../../hooks/admin-device/provisioningHook";
import { useCallback, useState } from "react";
import { saveStateType } from "../../models/types";
import { provisionDevices } from "../../utilities/axios/admin-api-calls/adminCalls";
import { ProvisionResults } from "../../models";
import { useNavigate } from "react-router-dom";
import BlackButton from "../buttons/BlackButton";
import ModelDisplay from "./ModelDisplay";
import { DeviceModel } from "../../models/detailedDevice.model";
import { useIsMobile } from "../../hooks/media-hooks/mediaHooks";
import EditIcon from "@mui/icons-material/Edit";
import ReadMoreText from "../read-more-text/ReadMoreText";
import {useTenantNavigate} from '../../utilities/helpers';
const ProvisionDevicesForm = () => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { form, validForm, updateModel, updateNumDevices } =
    useProvisioningForm();

  const [modelModalOpen, setModelModalOpen] = useState(!form.model);

  const navigate = useTenantNavigate();
  const [inputError, setInputError] = useState("");
  const [saveState, setSaveState] = useState<saveStateType>("default");

  const { storeProvisionResult } = useSessionStorageProvisionResult();

  const onProvisionResultRetrieved = useCallback(
    (provisionResult: ProvisionResults) => {
      storeProvisionResult(provisionResult);
      navigate("/devices/provision/results");
    },
    [navigate, storeProvisionResult]
  );

  const submit = useCallback(async () => {
    if (saveState === "loading") return;
    setSaveState("loading");

    var response = await provisionDevices({
      deviceSpecification: {
        modelId: form.model!.id,
      },
      numberOfNewDevices: form.numDevices!,
    });

    if (!response) {
      setSaveState("error");
      return;
    }

    setSaveState("success");
    onProvisionResultRetrieved(response);
  }, [saveState, form, onProvisionResultRetrieved]);

  const isValid = (input: string) => {
    const regex = /^(?:[1-9]\d{0,2}|100)$/;
    return regex.test(input);
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value.length === 0) {
      setInputError("");
      updateNumDevices(0);
      return;
    }

    if (!isValid(event.target.value)) {
      setInputError(`Must be positive 3-digit integer`);
      updateNumDevices(0);

      return;
    }

    let numDevices = parseInt(event.target.value);
    if (numDevices < 0 || 100 < numDevices) {
      setInputError(`Must be between 1 and 100`);
      updateNumDevices(0);
      return;
    }

    setInputError("");
    updateNumDevices(numDevices);
  };

  const handleCreateClicked = () => {
    if (validForm) {
      submit();
    }
  };

  const handleModelSelected = (model: DeviceModel) => {
    updateModel(model);
    setModelModalOpen(false);
  };

  return (
    <Container  maxWidth="sm" sx={{ paddingTop: 2 }}>
      {form.model ? (
        <>
          {saveState === "default" && (
            <Grid
              container
              flexDirection={"column"}
              justifyItems={"center"}
              maxWidth={"90vw"}
              rowSpacing={isMobile ? 3 : 5}
              alignItems={"center"}
              minHeight={190}
            >
              <Grid item container alignItems={"center"}>
                <Grid item>Model name</Grid>
                <Grid item container justifyContent={"space-between"}>
                  <Typography variant="h5">{form.model.name}</Typography>

                  <IconButton
                    onClick={() => setModelModalOpen(true)}
                    sx={{ marginLeft: "1rem" }}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item container flexDirection={"column"}>
                <Grid item>Prefix</Grid>
                <Grid item>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: theme.typography.fontWeightBold }}
                  >
                    {form.model.serialNumberPrefix}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container flexDirection={"column"}>
                <Grid item>Description</Grid>
                <Grid item>
                  <ReadMoreText
                    id={"model-description"}
                    text={form.model.description}
                    amountOfWords={16}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                flexDirection={"column"}
                width={"inherit"}
                rowSpacing={1}
              >
                <Grid item>Number of new devices</Grid>
                <Grid item paddingBottom={3} height={40}>
                  <TextField
                    type="number"
                    fullWidth
                    error={inputError.length > 0}
                    helperText={inputError}
                    defaultValue={form.numDevices}
                    onChange={handleTextFieldChange}
                  />
                </Grid>
              </Grid>
              <Grid item width={"inherit"} marginTop={2}>
                <BlackButton
                  variant="outlined"
                  disabled={!validForm}
                  onClick={handleCreateClicked}
                  fullWidth
                >
                  Create
                </BlackButton>
              </Grid>
            </Grid>
          )}
          {saveState === "loading" && (
            <Grid
              container
              flexDirection={"column"}
              justifyContent={"center"}
              alignContent={"center"}
              marginY={4}
              rowSpacing={2}
            >
              <Grid item alignSelf={"center"}>
                <CircularProgress size={40} sx={{ alignSelf: "center" }} />
              </Grid>
              <Grid item>
                <Typography align="center">
                  {(form?.numDevices ?? 0) > 1
                    ? `Creating ${form.numDevices} devices ...`
                    : `Creating device ...`}
                </Typography>
              </Grid>
            </Grid>
          )}
          {saveState === "error" && (
            <Grid
              container
              flexDirection={"column"}
              justifyContent={"center"}
              rowSpacing={3}
            >
              <Alert severity="error">
                An error occured when creating devices
              </Alert>
              <Button onClick={() => setSaveState("default")}>Ok</Button>
            </Grid>
          )}
        </>
      ) : (
        <BlackButton
          onClick={() => setModelModalOpen(true)}
          fullWidth
          sx={{ marginTop: 3 }}
        >
          Select a model
        </BlackButton>
      )}
      <>
        <Dialog open={modelModalOpen} onClose={() => setModelModalOpen(false)}>
          <DialogTitle sx={{ textAlign: "center" }}>Select Model</DialogTitle>
          <DialogContent>
            <ModelDisplay
              onModelChosen={handleModelSelected}
              disabledModelId={form.model?.id}
              width={400}
            />
          </DialogContent>
        </Dialog>
      </>
    </Container>
  );
};

export default ProvisionDevicesForm;
