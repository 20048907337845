import { useCallback}  from "react";
import { useNavigate } from "react-router-dom";
import { getFloorsForPremise } from "../../utilities/axios/admin-api-calls/tenant-calls/locationCalls";

import {
  PaginationQuery,
} from "../../utilities/api";
import ItemDisplay from "../display/ItemDisplay";
import { BaseModel } from "../../models";
import { Filter } from "../../models/filter.models";
import {useTenantNavigate} from '../../utilities/helpers';


type props = {
  premiseId: string
}

const PremiseFloorDisplay = ({premiseId}: props) => {
  const navigate = useTenantNavigate();

  const getPremiseFloorsDisplaysPaginationQuery = useCallback(
    (paginationQuery: PaginationQuery, filter: Filter) =>
    getFloorsForPremise(
        paginationQuery,
        premiseId,
        filter.searchTerm
      ),
    [premiseId]
  );

  const navigateToPremiseFloor = (floor: BaseModel) => {
    navigate(`floors/${floor.id}`);
  };
  return (
    <ItemDisplay itemName="floors" getItems={getPremiseFloorsDisplaysPaginationQuery} labelProperty={"name"} handleItemChosen={navigateToPremiseFloor} />
  );
};

export default PremiseFloorDisplay;


