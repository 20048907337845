import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoutes from "../routing/PrivateRoutes";
import { config } from "../config";
import { useDispatch } from "react-redux";
import { newTenant } from "../store/actions/tenantActions";
import {
	StorageKeys,
	StorageService,
} from "../utilities/storage/local-storage";

export const App: React.FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (config.resaniEnvironment !== "production") {
			const oldTitle = document.title;
			document.title = `${oldTitle} ${config.resaniEnvironment.toUpperCase()}`;
			return () => {
				document.title = oldTitle;
			};
		}
	});

	const handleTenantChosen = (identifier: string) => {
		const x = {
			identifier,
			name: identifier,
			realm: identifier,
		};
		dispatch(newTenant(x));
	};

	useEffect(() => {
		const url = new URL(window.location.href);

		if (url.pathname.startsWith("/resani/devices")) {
			const realm = StorageService.getItem(StorageKeys.PartnerId, "NONE");
			handleTenantChosen(realm);
		}
	}, []);

	return (
		<Routes>
			<Route path="/*" element={<PrivateRoutes />} />
		</Routes>
	);
};
