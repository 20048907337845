import { Link as RouterLink, LinkProps } from "react-router-dom";
import { Link, SxProps, Theme } from "@mui/material";

import { forwardRef, useMemo } from "react";
import { useTenant } from "../../hooks/tenant";
import { extractRealmFromUrl } from "../../utilities/helpers";

type props = {
	to: string;
	tenantSubLink?: boolean;
	children: string | React.ReactNode;
	sx?: SxProps<Theme> | undefined;
	target?: React.HTMLAttributeAnchorTarget;
	rel?: string;
};

const Linkage = ({
	to,
	tenantSubLink = false,
	target,
	rel,
	children,
	sx,
}: props) => {
	const tenant = useTenant();
	const realm = extractRealmFromUrl();
	const CustomLink = useMemo(
		() =>
			forwardRef<HTMLAnchorElement, Omit<LinkProps, "to">>(
				function Linker(linkProps, ref) {
					if (!tenantSubLink || !tenant)
						return <RouterLink ref={ref} to={to} {...linkProps} />;

					return <RouterLink ref={ref} to={to} {...linkProps} />;
				},
			),
		[to, tenantSubLink, tenant, realm],
	);

	return (
		<Link
			target={target}
			rel={rel}
			component={CustomLink}
			state={"state"}
			sx={sx}
		>
			{children}
		</Link>
	);
};

export default Linkage;
