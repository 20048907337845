import { Global } from "@emotion/react";
import { Box, Input, Typography } from "@mui/material";
import React, { useState } from "react";
import {
	StorageKeys,
	StorageService,
} from "../../utilities/storage/local-storage";
import { Button } from "../buttons/Button";
import { InputField } from "../input-field/InputField";

export const NoPartnerId = () => {
	const [partnerId, setPartnerId] = useState("");

	const handlePartnerId = () => {
		const url = new URL(window.location.href);
		const pathWithoutLeadingSlash = url.pathname.replace(/^\//, "");
		const newPathname = `/${partnerId}/${pathWithoutLeadingSlash}`;

		//StorageService.setItem(StorageKeys.PartnerId, partnerId);
		window.location.href = url.origin + newPathname + url.search;
	};

	return (
		<>
			<Global
				styles={{
					"html, body, #root": {
						height: "100%",
						width: "100%",
						margin: 0,
						padding: 0,
						position: "fixed",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						overflow: "hidden",
						background: "#424242",
						color: "white",
					},
				}}
			/>
			<Box
				sx={{
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					padding: 3,
				}}
			>
				<Typography
					variant="h4"
					sx={{
						fontSize: {
							xs: "1.15rem", // Mobile
							sm: "1.5rem", // Tablet
							md: "2rem", // Desktop
						},
						textAlign: "center",
						mb: 2,
					}}
				>
					Enter the partner name
				</Typography>
				<input
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							handlePartnerId();
						}
					}}
					style={{
						fontSize: "36px",
						height: "55px",
						width: "200px",
					}}
					type="text"
					onChange={(e) => setPartnerId(e.target.value.toLowerCase())}
					value={partnerId}
				/>
				<Button
					onClick={() => {
						handlePartnerId();
					}}
					disabled={!partnerId}
					style={{
						height: "55px",
						backgroundColor: "#7c80f8",
						fontSize: "22px",
						marginTop: "20px",
					}}
				>
					Login
				</Button>
			</Box>
		</>
	);
};
