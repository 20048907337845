import React, { useState, ChangeEvent, useCallback, useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { saveStateType } from "../../models/types";
import { uploadInstallationImage } from "../../utilities/axios/admin-api-calls/tenant-calls/installationCalls";
import { DeviceInstallation } from "../../models";

interface ImageUploadProps {
  deviceInstallation: DeviceInstallation;
  onUpload?: (image:File) => void;
  onImageSave: (saveState:saveStateType) => void
}

const InputImage: React.FC<ImageUploadProps> = ({
  deviceInstallation,
  onUpload,
  onImageSave
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [saveState, setSaveState] = useState<saveStateType>("default");
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl);
    }
  };

  useEffect(()=> onImageSave(saveState), [saveState,onImageSave])

  const handleUpload = useCallback(async () => {
    if (!selectedFile || saveState === "loading") return;

    setSaveState("loading");

    var res = await uploadInstallationImage(
      deviceInstallation.id,
      selectedFile
    );

    if (!res) {
      setSaveState("error");
      return;
    }

    if (onUpload) {
      onUpload(selectedFile);
    }

    handleClear();

    setSaveState("success");
  }, [onUpload, deviceInstallation.id, saveState, selectedFile]);

  const handleClear = () => {
    setSelectedFile(null);
    setPreview(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        p: 2,
        border: "1px dashed grey",
        borderRadius: 2,
        maxWidth: 400,
        mx: "auto",
      }}
    >
      <Typography variant="h6">Upload Image</Typography>

      <Button
        variant="contained"
        component="label"
        startIcon={<CloudUploadIcon />}
        disabled={saveState === "loading"}
      >
        Choose File
        <input
          type="file"
          accept="image/*"
          hidden
          onChange={handleFileChange}
        />
      </Button>

      {preview && (
        <Box
          sx={{
            position: "relative",
            width: 200,
            height: 200,
            border: "1px solid grey",
            borderRadius: 1,
            overflow: "hidden",
          }}
        >
          <img
            src={preview}
            alt="Preview"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <IconButton
            color="error"
            size="small"
            onClick={handleClear}
            sx={{
              position: "absolute",
              top: 4,
              right: 4,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      )}

      {selectedFile && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={saveState === "loading"}
          startIcon={
            saveState === "loading" ? <CircularProgress size={20} /> : null
          }
        >
          {saveState === "loading" ? "Uploading..." : "Upload"}
        </Button>
      )}
    </Box>
  );
};

export default InputImage;
