import { useState, useMemo, useCallback, useEffect } from "react";
import { BaseModel } from "../../models";
import {
  PagedResponse,
  PaginationQuery,
  emptyPagedResponse,
} from "../../utilities/api";
import { fetchStateType } from "../../models/types";
import { Filter } from "../../models/filter.models";

type props = {
  getItems: (
    paginationQuery: PaginationQuery,
    filter: Filter
  ) => Promise<PagedResponse<ItemWithId> | null>;
  labelProperty: string;
  handleItemChosen: (item: any) => void;
  maxItemsPerPage: number;
  getLabel?: (item: Item) => string;
  disableAutoSelectOnOneElement: boolean;
  unselectAfterChosen?:boolean
};

export const useItemPaginationSearch = ({
  getItems,
  labelProperty,
  handleItemChosen,
  getLabel,
  maxItemsPerPage,
  disableAutoSelectOnOneElement,
  unselectAfterChosen=false
}: props) => {
  const [apiData, setApiData] = useState<PagedResponse<Item>>(
    emptyPagedResponse<Item>()
  );
  const [items, newItems] = useState<Item[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [fetchstate, setFetchState] = useState<fetchStateType>("default");
  const [selected, setSelected] = useState<Item | null>(null);
  const [selectedFromList, setSelectedFromList] = useState(false);
  const inputValueEmpty = useMemo(() => inputValue.length === 0, [inputValue]);

  const onItemChosenCallback = useCallback((device:Item)=>{
    handleItemChosen(device)

    if(unselectAfterChosen)
    setSelected(null)
  }, [handleItemChosen, setSelected, unselectAfterChosen])

  const totalNumberOfPages = useMemo(
    () => apiData.totalPages,
    [apiData.totalPages]
  );
  const currentPage = useMemo(
    () => (apiData === emptyPagedResponse<Item>() ? 1 : apiData.currentPage),
    [apiData]
  );

  const showPaginationBar = useMemo(
    () => items.length > 0 && totalNumberOfPages > 1,
    [items.length, totalNumberOfPages]
  );

  const constructLabel = useCallback(
    (item: Item) => {
      if (getLabel) return getLabel(item);

      return (item as any)[labelProperty]?.toString() ?? item.id;
    },
    [getLabel, labelProperty]
  );



  const populateWithData = useCallback(
    async (page: number = 1, input: PopulateDataInput = defaultPopulateDataInput) => {
      if (fetchstate === "loading") return;

      if (input.shouldSetLoading){
        setFetchState("loading")
      }

      let newData = await getItems(
        new PaginationQuery(page, maxItemsPerPage),
        input.filter
      );
      if (!newData) {
        setFetchState("error");
        return;
      }

      setApiData(newData);
      newItems(newData.items);
      setFetchState("fetched");
    },
    [fetchstate, getItems, maxItemsPerPage]
  );

  const selectFirstItem = useCallback(() => {
    if (items.length === 1 && !disableAutoSelectOnOneElement) {
      setSelected(items[0]);
      setSelectedFromList(false);
    } else if (
      items.length > 0 &&
      constructLabel(items[0]) === inputValue &&
      !selectedFromList
    ) {
      setSelected(items[0]);
    } else if (!selectedFromList) {
      setSelected(null);
    }
  }, [
    items,
    inputValue,
    selectedFromList,
    constructLabel,
    disableAutoSelectOnOneElement,
  ]);

  useEffect(() => {
    if (!inputValueEmpty) {
      const timeoutId = setTimeout(async () => {
        await populateWithData(1, {filter:{searchTerm: inputValue}});
      }, 200);
      return () => clearTimeout(timeoutId);
    }
  }, [inputValue, inputValueEmpty, populateWithData]);

  useEffect(() => {
    if (fetchstate === "default") {
      populateWithData();
    }
  }, [fetchstate, populateWithData]);

  useEffect(() => {
    selectFirstItem();
  }, [items, selectFirstItem]);

  const onKeyPress = (event: React.KeyboardEvent) => {
    if (!selected || event.key !== "Enter") return;

    onItemChosenCallback(selected);
  };

  const handlePaginationChange = (
    _: React.ChangeEvent<unknown>,
    value: number
  ) => {
    populateWithData(value);
  };

  const handlePaginatedListSelection = (item: Item) => {
    setSelectedFromList(true);
    setSelected(item);
  };

  return {
    selectFirstItem,
    setSelected,
    onKeyPress,
    setInputValue,
    setSelectedFromList,
    populateWithData,
    items,
    constructLabel,
    selected,
    handlePaginatedListSelection,
    showPaginationBar,
    totalNumberOfPages,
    currentPage,
    handlePaginationChange,
    fetchstate,
    reload: populateWithData
  };
};

export interface Item extends BaseModel {
  label?: string;
}
export interface ItemWithId extends Item, BaseModel {}


interface PopulateDataInput{
  filter: Filter
  shouldSetLoading?:boolean
}

const defaultPopulateDataInput: PopulateDataInput = {filter:{searchTerm: ""}, shouldSetLoading:false}